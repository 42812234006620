import { useEffect, useState } from 'react'
import BaseLayoutStore from 'components/Layout/BaseLayoutStore'
import NotificationForm from 'components/Customs/Modal/NotificationForm';
import ModalForm from 'components/Result/ModalForm'
import CardService from 'components/Services/Card/CardService';
import CardOtherService from 'components/Services/Card/CardOtherService';
import SkeletonCardService from 'components/Services/Card/SkeletonCardService'
import FormContactStore from 'components/Customs/Contact/FormContactStore'
import services_and_accessories from 'assets/images/services_and_accessories.svg'
import MapaAuto from 'components/Home/Used/MapaAuto'
import { useParams, useHistory, useLocation } from 'react-router-dom'

//import { homeService } from "data/DescripcionHead.json"
import { useServiceByAuthorizedStore } from "hooks/AuthorizedStores/useServiceByAuthorizedStore"
import { useConfig } from "hooks/MetaHead/useConfig"


const DetailStore = () => {
	const location = useLocation()
	const { slug } = useParams()
	const history = useHistory()
	const { state, actions, formElement } = useServiceByAuthorizedStore()
	const [ descriptionMeta, setDescriptionMeta ] = useState({})

	const { itemSelectedListForm, loadingCategoriesProvider, isDataService, serviceSelected } = state
	const { getDataServiceProvider, selectedQuote, handleMoveTo } = actions 
	const { handleActive, disabledBtn, register,  handleSubmit, onSubmit, message, isOpen, closeModal, isLoading,
		isOpenForm, closeModalForm, errors, setFormToken } = formElement
	const { getDataMetaTag } = useConfig()

	if(isDataService===0){
		history.go(-1)
	}

	const getDataInitial = async (slug) => {
		const infoMeta = await getDataMetaTag(location?.pathname)
		setDescriptionMeta(infoMeta)
		//const store = typeof location?.state?.store !=="undefined" ? location?.state?.store : null
		getDataServiceProvider(slug)	
	}

	useEffect(() => {
		getDataInitial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slug])

	return (
		<BaseLayoutStore
			infoMetaHead={descriptionMeta}
			infoHeader={serviceSelected} 
			classStyle={{
				classTitle: "header-separator"
			}}
			loading={loadingCategoriesProvider}
			isDataService={isDataService}
			isVisibleIcon={true} 
			handleMoveTo={handleMoveTo}
		>
			<div className="row">
				<div className="col-12 service-result">
					<div className="container">
						<div className="service-result">
							
							{!loadingCategoriesProvider && serviceSelected?.list?.length=== 0 && (
								<p className="service-result__text">No hay resultados disponible</p>
							)}
								
						</div>
						<div className="service__container--default service__new_result--default">
							{(!loadingCategoriesProvider && slug!=="ferripaldi") && serviceSelected?.list?.map((item, key) => 
									<CardService 
										key={key} 
										{...item} 
										selectedQuote={selectedQuote} 
									/>
								)
							}
							{(!loadingCategoriesProvider && slug==="ferripaldi") && serviceSelected?.list?.map((item, key) => 
									<CardOtherService 
										key={key} 
										{...item} 
										selectedQuote={selectedQuote} 
									/>
								)
							}
							{loadingCategoriesProvider && Array.from([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]).map(x => 
								<SkeletonCardService  key={`item-${x}`} />)}
						</div>
					</div>
				</div>
			</div>
            

            <MapaAuto 
                imagen={services_and_accessories}
                title="Derco"
                bgColor="bg-white"
            >
                <p className="panel__title panel__title--service mt-5">Dónde puedo encontrarlo</p>
            </MapaAuto>

            

			<div className="row form-contact--store">
				<div className="col-12">
					<FormContactStore 
						isVisibleVIN={false} 
						itemService={serviceSelected}
						styleForm={{main:"service-form-contact--white"}}
						provider={serviceSelected?.name}
					/>
				</div>
			</div>

			{isOpenForm && <ModalForm 
				show={isOpenForm} 
				handleClose={closeModalForm} 
				register={register} 
				handleSubmit={handleSubmit} 
				onSubmit={onSubmit}
				isLoading={isLoading}
				infoSendItem={itemSelectedListForm}
				handleActive={handleActive}
				disabledBtn={disabledBtn}
				provider={serviceSelected}
				errors={errors}
				setFormToken={setFormToken}
				checkValue={false}
			/>}
            {isOpen && <NotificationForm 
					show={isOpen} 
					handleClose={closeModal} 
					message={message}
				/>
			}
		</BaseLayoutStore>
		
	)
}


export default DetailStore