import {
	INITIAL_DATA,
	UPDATE_DATA_NORMAL,
	UPDATE_ITEMS_SEARCH,
	SELECTED_TAB_SEARCH,
	UPDATE_SELECTED_INTEREST,
	SAVE_INSTEREST,
	SAVE_INSTEREST_FORM,
	VERIFY_SELECTED_INTEREST,
	UPDATE_SEARCH_INTEREST,
	UPDATE_BRAND_MODEL_SEARCH,
	SAVE_PREFERENCES_INTEREST,
	INITIAL_HOME,
	UPDATE_PARAMS_NOT_FOUND,
	UPDATE_PAGE_LAST,
	INITIAL_PANEL,
	INITIAL_BRAND_SEARCH,
	UPDATE_BRAND_NORMAL_SEARCH,
	UPDATE_SELECTED_BRAND_COMBO,
	DELETE_BRAND_SELECTED_COMBO,
	LIST_SELECTED_MODEL_COMBO,
	DELETE_MODEL_SELECTED_COMBO,
	UPDATE_DATA_NORMAL_ITEM
} from 'services/types/searchType';

import { vTypeTransmision, vTypeAuto, vTypeCombustible, vTypeCarroceria } from 'data/Normal';
import { listInterestAll } from 'data/Interests';
import { allInterestNew, allInterestUsed, allBodyWorkNew, allBodyWorkUsed } from 'data/Preferences';
import { generateYear, generateKm, getItemsOfSelected, getItemsSendForm, verifyCheckList } from 'utils/tools';

import { checkMarkedSearchOptions, getOptionsSearchMarked, listMarkedSearchOptions, 
	verifyArrayKmYearRange, generateFormatURLPrice, generateFormatURLYear, generateFormatURLKm, verifyParamsURL,
	formtTitleHeader } from 'utils/SearchResultCar/functionsSearch';

import { formatArraySearchItems, checkMarkedSearchOptionsInterest, formatPerformance, 
	getOptionsSearchMarkedInterest, selectedItemListInterest, getListItemInterest } from 'utils/SearchResultCar/functionSearchInterest';


const yearInitial = process.env.REACT_APP_YEAR_INITIAL;
const RANGE_MAX_PRICE = 100000000;


export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const selectedtPageCurrent = (url) => (dispatch, getState) => {
	const payload = { urlLastPage: url }
	dispatch(requestSuccess(UPDATE_PAGE_LAST, payload));
};

export const getDataInitialSearch = (typeHome) => (dispatch, getState) => {

	const { initialNormalDefault } = getState().search;
	let dataInitial = JSON.parse(JSON.stringify(initialNormalDefault));

	const vTypeCar = vTypeAuto.map(item => {
		item.checked= (item.value===typeHome) ? true : false;
		return item;
	});

	const valueNormal = {
		...dataInitial,
	    vTypeAuto: vTypeCar,
	    vMarca: [],
	    vModelo: [],
	};

	const valueInterest = {
		vPrice: {min: 0, max: RANGE_MAX_PRICE },
		vTypeAuto: vTypeCar,
		listInterestAll
	};

	const selectedTypeAuto = valueNormal.vTypeAuto.find(x=>x.checked===true);

	const payload = { 
		normal: valueNormal, 
		insterest: valueInterest, 
		listInterestNew: allInterestNew,
		listInterestUsed: allInterestUsed,
		listInterestAll,
		normalDefault: valueNormal,
		typeHome,
		selectedTab: 'search-normal',
		allBodyWorkNew,
		allBodyWorkUsed,
		itemOfSearch: [],
		itemsForm: [],
		itemSelectedBrandANDModel: {
			brands: [],
			models: []
		}
	};

	dispatch(requestSuccess(INITIAL_DATA, payload));

	return { status: true, selectedTypeAuto };
};

export const getDataInitialSearchBrandModel = (typeHome) => (dispatch, getState) => {

	const { initialNormalDefault } = getState().search;
	let dataInitial = JSON.parse(JSON.stringify(initialNormalDefault));

	const { listBrandsSelected } = getState().marcas;

	const valueNormal = {
		...dataInitial,
	    vMarca: listBrandsSelected,
	    vModelo: [],
	};

	const payload = { 
		normal: valueNormal
	};
	
	dispatch(requestSuccess(INITIAL_BRAND_SEARCH, payload));

	return { status: true, message: "" };
};

export const getConfigPanel = (value) => (dispatch, getState) => {
	const payload = value;

    dispatch(requestSuccess(INITIAL_PANEL, payload));
};




/*******************Filtro Normal********************************/
/****sii */
export const generateUrlSearchNormal = (body) => (dispatch, getState) => {
	try{
		const { initialNormalDefault } = getState().search;
	
		const dataInitial = JSON.parse(JSON.stringify(initialNormalDefault));
		let params = [];
		let paramsSendForm = body;

		for(let key in paramsSendForm){
			if(key==="vCarroceria" || key==="vCombustible" || key==="vTransmision" || key==="vTypeAuto" || key==="vOrigin" || 
					key==="vSeatMove" || key==="vTraction" || key==="vMarca" || key==="vPerformance"){
				const selected = paramsSendForm[key].filter(item => item.checked===true);

				if(selected.length > 0){
					params = [...params, ...selected.map(item => { return {slug: item.slug }})];
				}
			}else if(key==="vTypeRange"){
				let verifyChangePrice =  listMarkedSearchOptions(paramsSendForm["vTypeRange"], dataInitial?.vTypeRange);

				if(verifyChangePrice!==""){
					let minPrice = generateFormatURLPrice(paramsSendForm[key].min.toString());
					let maxPrice = generateFormatURLPrice(paramsSendForm[key].max.toString());;
					let formatNamePrice = minPrice!=="" ? `${minPrice}-hasta-` : `hasta-`;
					formatNamePrice = `${formatNamePrice}${maxPrice}`;
	
					params = [...params, { slug: formatNamePrice }];
				}
			} else if(key==="vTypeYear"){
	
				let verifyChangeYear =  listMarkedSearchOptions(paramsSendForm["vTypeYear"], dataInitial?.vTypeYear);

				if(verifyChangeYear!==""){
					let minYear = generateFormatURLYear(paramsSendForm["vTypeYear"].min.toString(), dataInitial["vTypeYear"].min);
					let maxYear = generateFormatURLYear(paramsSendForm["vTypeYear"].max.toString(), dataInitial["vTypeYear"].min);
					let formatNameYear = minYear!=="" ? `${minYear}-hasta-` : ``;
					formatNameYear = `${formatNameYear}${maxYear}`;
	
					params = [...params, { slug: formatNameYear }];
				}
			} else if(key==="vTypeKm"){
				let verifyChangeKm =  listMarkedSearchOptions(paramsSendForm["vTypeKm"], dataInitial?.vTypeKm);
				if(verifyChangeKm!==""){
					let minKm = generateFormatURLKm(paramsSendForm["vTypeKm"].min.toString());
					let maxKm = generateFormatURLKm(paramsSendForm["vTypeKm"].max.toString());
					let formatNameKm = minKm!=="" ? `${minKm}-hasta-` : `hasta-`;
					formatNameKm = `${formatNameKm}${maxKm}`;
	
					params = [...params, { slug: formatNameKm }];
				}
			}else if(key==="vModelo"){
				// eslint-disable-next-line 
				for(const [brand, options] of Object.entries(paramsSendForm["vModelo"])){
					
					const selected = options.filter(item => item.checked===true);
					if(selected.length > 0){
						params = [...params, ...selected.map(item => { return {slug: item.slug }})];
					}
				}
			}
		}			

		let slugEnd = "";
		const posIndexNew = params.findIndex(item=> item.slug==="nuevos");
		if(posIndexNew!==-1){
			slugEnd = "nuevos/";
			params.splice(posIndexNew, 1);
		}

		const posIndexUsed = params.findIndex(item=> item.slug==="usados");
		if(posIndexUsed!==-1){
			slugEnd = slugEnd + "usados/";
			params.splice(posIndexUsed, 1);
		}
		
		slugEnd = slugEnd + params.map(item => item.slug).join("/");

		let slug = slugEnd;

		return { status: true, slug };

	}catch(error){
      	return { status: false, message: error };
    }
};

/******Parámetros con busqueda normal desde la página Resultado de búsqueda***************/
/***siiii */
export const getSearchByParamsUrl = ({ params }) => (dispatch, getState) => {
	try{
		let { initialNormalDefault } = getState().search;
	
		let updateParamsSelected = JSON.parse(JSON.stringify(initialNormalDefault));
		updateParamsSelected["vTypeAuto"] = updateParamsSelected["vTypeAuto"].map(item => {
												return {
													...item,
													checked: false
												}
											})
		let { arrParamsSearchForm, arrayFountParams } = verifyParamsURL(updateParamsSelected, params);

		let validateParams = params.filter(item => !item.includes("millones") 
												&& !item.includes("mil") 
												&& !item.includes("m") 
												&& !item.includes("ano")
											);

		if(arrayFountParams.length<validateParams.length){
			const payload = { titleHeader: formtTitleHeader(params) };
			dispatch(requestSuccess(UPDATE_PARAMS_NOT_FOUND, payload));

			return { status: false, data: arrParamsSearchForm };
		}

		return { status: true, data: arrParamsSearchForm };

	} catch(error){
      	return { status: false, message: error, data: [] };
    }
};
/*******siiii */
export const saveSearchNormal = (body, visibleModal=false, pathname, infoMeta) => (dispatch, getState) => {
	try{
	
		const { initialNormalDefault } = getState().search;
	
		const dataInitial = JSON.parse(JSON.stringify(initialNormalDefault));

		let valueDataSearchNormal = {
			...body,
			vTypeRange: listMarkedSearchOptions(body?.vTypeRange, dataInitial?.vTypeRange),
			vTypeKm: listMarkedSearchOptions(body?.vTypeKm, dataInitial?.vTypeKm),
			vTypeYear: listMarkedSearchOptions(body?.vTypeYear, dataInitial?.vTypeYear),
		};

	
		const { slug, optionsSendApi, itemOptionsFormSend, titleHeader } = getOptionsSearchMarked(valueDataSearchNormal, pathname, initialNormalDefault, infoMeta);
	
		const valueDataSearchNormalUpdate = {
			...optionsSendApi,
			vTypeRange: verifyArrayKmYearRange(optionsSendApi?.vTypeRange, dataInitial?.vTypeRange),
			vTypeKm: verifyArrayKmYearRange(optionsSendApi?.vTypeKm, dataInitial?.vTypeKm),
			vTypeYear: verifyArrayKmYearRange(optionsSendApi?.vTypeYear, dataInitial?.vTypeYear)
		};
	
		const valueFormInitialUpdate = {
			...body,
			vTypeRange: verifyArrayKmYearRange(optionsSendApi?.vTypeRange, dataInitial?.vTypeRange),
			vTypeKm: verifyArrayKmYearRange(optionsSendApi?.vTypeKm, dataInitial?.vTypeKm),
			vTypeYear: verifyArrayKmYearRange(optionsSendApi?.vTypeYear, dataInitial?.vTypeYear)
		};

		let isButtonClearAll = true

		if(slug==="nuevos" || slug==="usados" || slug==="nuevos/usados" || slug==="usados/nuevos"){
			isButtonClearAll = false
		}

		let verifiedChekedBrand = valueFormInitialUpdate?.vMarca.filter(item => item.checked===true)
		let hiddenOrigin = false
		if(verifiedChekedBrand.length > 0){
			hiddenOrigin = true
		}

		const payload = { 
			values: valueFormInitialUpdate,
			items: itemOptionsFormSend, 
			itemsForm: valueDataSearchNormalUpdate,
			initialData: JSON.parse(JSON.stringify(initialNormalDefault)),
			visibleModal,
			titleHeader,
			searchParamsView: true,
			isButtonClearAll,
			hiddenOrigin
		};

		dispatch(requestSuccess(UPDATE_DATA_NORMAL, payload));

		return { status: true, items: itemOptionsFormSend, slug };

	}catch(error){

      	return { status: false, message: error };
    }
};

export const saveItemSearchNormal = (body) => (dispatch, getState) => {
	try{
	
		const { initialNormalDefault } = getState().search;
	
		const dataInitial = JSON.parse(JSON.stringify(initialNormalDefault));
		
		let valueDataSearchNormal = {
			...dataInitial,
			vTypeAuto: checkMarkedSearchOptions(body?.vTypeAuto, dataInitial?.vTypeAuto),
			vCarroceria: checkMarkedSearchOptions(body?.vCarroceria, dataInitial?.vCarroceria),
			vTypeRange: verifyArrayKmYearRange(body?.vTypeRange, dataInitial?.vTypeRange),
		};
	
		const payload = {
			values: valueDataSearchNormal
		}
	
		dispatch(requestSuccess(UPDATE_DATA_NORMAL_ITEM, payload));

		return { status: true, message: "" };

	}catch(error){
      	return { status: false, message: error };
    }
};

/****siii */
export const deleteItemSearchNormalInterest = (paramsSendForm, typeSearch="") => (dispatch, getState) => {
	try{
		const { itemOfSearch } = getState().search;
	
		let itemUpdate = []
		if(typeSearch==="all"){
			itemUpdate = itemOfSearch.filter(item => item.value==="car-new" || item.value==="car-used");
		}else{
			itemUpdate = itemOfSearch.filter(item => item.value!==paramsSendForm.value);
		}
		
		let objParamsUrl = [];

		for(const key in itemUpdate){
			objParamsUrl.push(itemUpdate[key]["slug"]);
		}

		let slug = objParamsUrl.join("/");
		let isButtonClearAll = true

		if(slug==="nuevos" || slug==="usados" || slug==="nuevos/usados" || slug==="usados/nuevos"){
			isButtonClearAll = false
		}
		
		const payload = { items: itemUpdate, isButtonClearAll };
		
		dispatch(requestSuccess(UPDATE_ITEMS_SEARCH, payload));	

		return { status:true, url: slug };
	}catch(err){
		return { status:false, url: null };
	}	
};

export const clearItemSearchAll = () => (dispatch, getState) => {
	const payload = { items: [] };
		
	dispatch(requestSuccess(UPDATE_ITEMS_SEARCH, payload));		
};

export const updateFilterSearchNormal = () => (dispatch, getState) => {

	const { itemOfSearch } = getState().search;

	let objParamsUrl = [];

	for(const key in itemOfSearch){
		objParamsUrl.push(itemOfSearch[key]["slug"]);
	}

	let slug = objParamsUrl.join("/");

	return { status:true, data: slug };
};

/****No va */
export const updateItemBrandModel = (body) => (dispatch, getState) => {

	const { initialDataNormal } = getState().search;
	const { selectedBrandAndModel } = getState().marcas;

	const listMarca = selectedBrandAndModel?.brands ? selectedBrandAndModel?.brands : [];
	const listModelo = selectedBrandAndModel?.models ? selectedBrandAndModel?.models : [];

	const payload = { ...initialDataNormal, vMarca: listMarca, vModelo: listModelo };

	dispatch(requestSuccess(UPDATE_BRAND_MODEL_SEARCH, payload));
};

export const getSelectedTab = (body) => (dispatch, getState) => {
	try{
		const { initialNormalDefault, allInterest, initialInterestDefault  } = getState().search;

		const interest = allInterest.map(item => {
			item.isChecked=false;
			const listOptions = item.listado.map(option => {
				option.isChecked=false;
				return option;
			})
			item.listado = listOptions;
			return item;
		});

		const payload = {
			tabSelected: body,
			dataNormal: initialNormalDefault,
			itemOfSearch: [],
			itemsForm: {},
			allInterest: interest,
			listInterestSelected: [],
			dataInterest: initialInterestDefault,
		};
		
		dispatch(requestSuccess(SELECTED_TAB_SEARCH, payload ));
	}catch(error){
      	return { status: false, message: error };
    }	
};

export const addSelectSearchInterest = (id) => (dispatch, getState) => {
	const { allInterest } = getState().search;

	let items = allInterest;
	const index = items.findIndex(insterest => insterest.id===parseInt(id));
    items[index].isChecked = true;
	
	const payload = {
		listadoUpdate: items,
		interestSelected: items[index]
	};

	dispatch(requestSuccess(UPDATE_SELECTED_INTEREST, payload));
};

export const addNewInsterest = (item, type) => (dispatch, getState) => {
	const { allInterest, initialDataInterest } = getState().search;

	let items = allInterest;
	
	const index = items.findIndex(insterest => insterest.id===parseInt(item.idInterest));
	const indexOption = items[index].listado.findIndex(option => option.id===parseInt(item.id));
	items[index].listado[indexOption].isChecked = !items[index].listado[indexOption].isChecked;

	let selectedsOption = {};
	// eslint-disable-next-line
	for(let [key, option] of Object.entries(items)){
		
		const selected = option.listado.filter(x=>x.isChecked===true);
		for(let index in selected){
			if(!Object.keys(selectedsOption).includes(option.name)){
				selectedsOption[option.name] = [];
			}
			selectedsOption[option.name].push({ ...selected[index], checked: true });
		}
	}

	const payload = {
		insterests: items,
		listInterest: selectedsOption,
		dataInterest: initialDataInterest,		
	};

	dispatch(requestSuccess(SAVE_INSTEREST, payload));
};

export const verifyCheckedCloseModal = () => (dispatch, getState) => {
	try{
		const { allInterest } = getState().search;

		const interests = allInterest.map(item => {
			const listOptions = item.listado.filter(option => option.isChecked===true);
			item.isChecked=listOptions.length>0 ? true : false;
			return item;
		});

		const payload = { interests };

		dispatch(requestSuccess(VERIFY_SELECTED_INTEREST, payload));

	} catch(error){
      	return { status: false, message: error, data: [] };
    }
};
/**********************Busqueda por intereses*************************/
export const getFormatByInterests = (body, visibleModal=false) => (dispatch, getState) => {
	try{

		const { initialInterestDefault } = getState().search;
		
		const dataInitial = JSON.parse(JSON.stringify(initialInterestDefault));

		let arrParamsSearchForm = formatArraySearchItems(dataInitial);
		
		let valueDataSearchInterest = {
			vTypeAuto: checkMarkedSearchOptions(body?.vTypeAuto, arrParamsSearchForm?.vTypeAuto),
			vOrigin: checkMarkedSearchOptionsInterest(body?.vOrigin, arrParamsSearchForm?.vOrigin),
			vEcoFriendly: checkMarkedSearchOptionsInterest(body?.vEcoFriendly, arrParamsSearchForm?.vEcoFriendly),
			vFuel: checkMarkedSearchOptionsInterest(body?.vFuel, arrParamsSearchForm?.vFuel),
			vSeatMove: checkMarkedSearchOptionsInterest(body?.vSeatMove, arrParamsSearchForm?.vSeatMove),
			vTraction: checkMarkedSearchOptionsInterest(body?.vTraction, arrParamsSearchForm?.vTraction),
			vTransmission: checkMarkedSearchOptionsInterest(body?.vTransmission, arrParamsSearchForm?.vTransmission),
			vPrice: listMarkedSearchOptions(body?.vPrice, arrParamsSearchForm?.vPrice),
			vPerformance: formatPerformance(body?.vPerformance, checkMarkedSearchOptionsInterest(body?.vPerformance, arrParamsSearchForm?.vPerformance))
		};

		const { slug, optionsSendApi, itemOptionsFormSend, titleHeader } = getOptionsSearchMarkedInterest(valueDataSearchInterest);

		const valueDataSearchInterestUpdate = {
			...optionsSendApi,
			vPrice: verifyArrayKmYearRange(optionsSendApi?.vPrice, dataInitial?.vPrice),
			//vPerformance:  (optionsSendApi?.vPerformance==="") ? { min: 0, max: 100} : optionsSendApi?.vPerformance
		};
	
		let valueSearchInterest = {
			...valueDataSearchInterest,
			vPerformance: checkMarkedSearchOptionsInterest(body?.vPerformance, arrParamsSearchForm?.vPerformance)
		};

		const valueInterest = {
			vPrice: valueDataSearchInterestUpdate?.vPrice,
			vTypeAuto: checkMarkedSearchOptions(body?.vTypeAuto, arrParamsSearchForm?.vTypeAuto),
			allInterest: selectedItemListInterest(dataInitial.listInterestAll, valueSearchInterest)
		};

		const itemSelectedInterest = getListItemInterest(valueInterest.allInterest);

		const payload = { 
			data: valueDataSearchInterestUpdate, 
			items: itemOptionsFormSend, 
			valueInterest, 
			visibleModal,
			listInterest: itemSelectedInterest,
			titleHeader
		};

		dispatch(requestSuccess(SAVE_INSTEREST_FORM, payload));

		return { status: true, items: itemOptionsFormSend, slug };

    }catch(error){
      	return { status: false, message: error, data: [] };
    }
};

export const updateInterestFilter = (body) => (dispatch, getState) => {
	try{
		const { allInterest, initialDataInterest, listInterest } = getState().search;
		
		let insterests = allInterest;
		let itemsForms = [];

		for(const key in body){

			const index = insterests.findIndex(x => x.name===key);

			if(index!==-1){
				for(const keyItems in body[key]){

					const items = insterests[index].listado.map(item=>{
						if(item.valueForm===body[key][keyItems].name){
							item.isChecked=body[key][keyItems].checked;
							if(item.isChecked){
								itemsForms.push(item);
							}
						}
						return item;
					})
					insterests[index].listado=items;
				}
				const isChecked = insterests[index].listado.filter(x=>x.isChecked===true);
				insterests[index].isChecked=isChecked ? true : false;
			}
		}

		for(const key in listInterest){
			const existe = itemsForms.find(item=> item.name===listInterest[key].name);
			if(!existe){
				itemsForms.push(listInterest[key]);
			}
		}


		const valueInterest = {
			vTypeRange: body?.vTypeRange || initialDataInterest?.vTypeRange,
			vTypeAuto: verifyCheckList(body?.vTypeAuto, initialDataInterest.vTypeAuto),
			listInterestAll: insterests
		};

		const payload = {
			insterests,
			listInterest: itemsForms,
			dataInterest: valueInterest
		};

		dispatch(requestSuccess(SAVE_INSTEREST, payload));

		return { status: true, items: itemsForms };
	} catch(error){
      	return { status: false, message: error, data: [] };
    }
};


export const updateFilterSearchInterest = () => (dispatch, getState) => {

	const { updateAllInterest, updateInitialDataInterest } = getState().search;
	
	let items = [];
	let interestList = {};

	for(let key in updateAllInterest){
		const itemsSelected = updateAllInterest[key].listado.filter(x=>x.isChecked===true);
		const options =updateAllInterest[key];
		interestList[options.name] = options.listado.map(x => { return { name: x.value, checked: x.isChecked }});
		items=[...items, ...itemsSelected];
	}

	interestList = {...interestList, vTypeAuto: updateInitialDataInterest?.vTypeAuto, vTypeRange: updateInitialDataInterest?.vTypeRange};

	const payload = { allInterest: updateAllInterest, values: updateInitialDataInterest, listInterest: items };

	dispatch(requestSuccess(UPDATE_SEARCH_INTEREST, payload));

	return {status: true, items: payload.values, data: interestList };
};

export const savePreferencesNormal = (body) => (dispatch, getState) => {
	try{
		const years = generateYear(2022, 2000);

		const kms = generateKm(0, 100000);

		const valueNormal = {
		      vTypeTransmision: verifyCheckList(body?.vTransmision, vTypeTransmision),
		      vTypeAuto: verifyCheckList(body?.vTypeAuto, vTypeAuto),
		      vTypeCombustible: verifyCheckList(body?.vCombustible, vTypeCombustible),
		      vTypeRange: body?.vTypeRange ? body?.vTypeRange : {min: 0, max: RANGE_MAX_PRICE },
	      	  vTypeYear: body?.vTypeYear ? body?.vTypeYear : [ {name: years[years.length-1].value}, {name: years[0].value}],
		      vTypeKm: body?.vTypeKm ? body?.vTypeKm : [ {name: kms[0].value}, {name: kms[kms.length-1].value}],
		      vTypeCarroceria: verifyCheckList(body?.vCarroceria, vTypeCarroceria),
		      vMarca:[],
		      vModelo: []
		};
		let itemsOfSearchSelected = getItemsOfSelected(valueNormal);

		let itemsOfSendForm = getItemsSendForm(valueNormal);

		const verifyPrice = itemsOfSearchSelected.find(item => item.name==="vTypeRange");
		if(verifyPrice){
			if(verifyPrice.title===`0 - ${RANGE_MAX_PRICE}`){
				itemsOfSearchSelected = itemsOfSearchSelected.filter(item => item.name!=="vTypeRange");
			}
		}

		const verifyYear = itemsOfSearchSelected.find(item => item.name==="vTypeYear");
		if(verifyYear){
			if(verifyYear.title===`${yearInitial} - 2022`){
				itemsOfSearchSelected = itemsOfSearchSelected.filter(item => item.name!=="vTypeYear");
			}
		}

		const verifyKm = itemsOfSearchSelected.find(item => item.name==="vTypeKm");
		if(verifyKm){
			if(verifyKm.title===`0 - 1000000`){
				itemsOfSearchSelected = itemsOfSearchSelected.filter(item => item.name!=="vTypeKm");
			}
		}

		const payload = { 
			values: valueNormal, 
			items: itemsOfSearchSelected, 
			itemsForm: itemsOfSendForm 
		};
		dispatch(requestSuccess(UPDATE_DATA_NORMAL, payload));

		return { status: true, items: itemsOfSendForm };
	}catch(error){
      	return { status: false, message: error };
    }
};

export const savePreferencesInterest = (body) => (dispatch, getState) => {
	try{
		let { allInterest } = getState().search;

		let arrayInterests = body;
		let selectedInterest = [];
	
		const interestSendForm = allInterest.map(item => item.name);
		
		for(let key in interestSendForm){
			if(arrayInterests.hasOwnProperty(interestSendForm[key])){

				const indexInterest = allInterest.findIndex(x => x.name===interestSendForm[key]);
				if(indexInterest!==-1){
					
					let listado = allInterest[indexInterest].listado;
					for(let index in arrayInterests[interestSendForm[key]]){
						listado = allInterest[indexInterest].listado.map(item => {
							if(item.id===arrayInterests[interestSendForm[key]][index].id){
								item.isChecked=true;
								selectedInterest.push(item);
							}
							return item;
						})
					}
					allInterest[indexInterest].listado = listado;					
				}
			}
		}

		const dataForm = { vTypeRange: body?.vTypeRange, vTypeAuto: body?.vTypeAuto };

		const payload = { list: allInterest, selectedInterest };

		dispatch(requestSuccess(SAVE_PREFERENCES_INTEREST, payload));

		return { status: true, data: dataForm };
		
	} catch(error){
      	return { status: false, message: error, data: [] };
    }
};

export const getTypeHome = (value) => (dispatch, getState) => {
	localStorage.setItem("home-initial", JSON.stringify(value));
    dispatch(requestSuccess(INITIAL_HOME, value));
};



/*************************Parámetros con busqueda de intereses desde la página Resultado de búsqueda***************************/
export const getSearchInterestByParamsUrl = ({ params }) => (dispatch, getState) => {
	
	try{
		let { initialInterestDefault } = getState().search;
	
		let arrParamsSearchForm = formatArraySearchItems(initialInterestDefault);
		
		for(let key in params){

			for(let index in arrParamsSearchForm){

				if(index!=="vPrice" && index!=="vPerformance"){

					let searchItemId = arrParamsSearchForm[index].findIndex(item => item.slug===params[key]);

					if(searchItemId!==-1){
						arrParamsSearchForm[index][searchItemId].checked = true;
						break
					}
				}else if(params[key].includes("millones") || params[key].includes("mil")) {
					const value = params[key].includes("millones") 
									? `${params[key].replace("-millones", "000000")}`
									: `${params[key].replace("-mil", "000")}`;
					arrParamsSearchForm["vPrice"] = { min: 0, max: parseInt(value) };
				} else if(params[key].includes("kilometraje")) {
					let items = [];
					const valueParam = parseInt(params[key].replace("-kilometraje", ""));
					if(valueParam>= 10){
						let optionSelected = arrParamsSearchForm["vPerformance"][0];
						items.push({...optionSelected, checked: true});
					}
					if(valueParam>= 15){
						let optionSelected = arrParamsSearchForm["vPerformance"][1];
						items.push({...optionSelected, checked: true});
					}
					if(valueParam>16){
						let optionSelected = arrParamsSearchForm["vPerformance"][2];
						items.push({...optionSelected, checked: true});
					}
					arrParamsSearchForm["vPerformance"] = items;
				}			
			}
		}
		return { status: true, data: arrParamsSearchForm };

	}catch(error){
      	return { status: false, message: error, data: [] };
    }
};

/*************************Filtro de Marca y modelos***************************/
export const selectedBrandSearch = (itemBrand) => (dispatch, getState) => {
	try{
		const { updateDataNormal } = getState().search;
		
		const updateData = JSON.parse(JSON.stringify(updateDataNormal));
	
		let listModelSearch = updateData.vModelo
	
		let brandByModel = listModelSearch.find(item => item.slug===itemBrand.slug)
	
		if(!brandByModel){
			listModelSearch.unshift(itemBrand)
		}

		const payload = { 
			data: {...updateDataNormal, vModelo: listModelSearch }

		};
	
		dispatch(requestSuccess(UPDATE_BRAND_NORMAL_SEARCH, payload));

		return { status: true, message: "" };
	}catch(error){
      	return { status: false, message: error };
    }
};


/****************************Marca y Modelo */
export const getSelectedBrand = (id, check) => (dispatch, getState) => {
	try{

		let { initialDataNormal } = getState().search;

		let dataInitial = JSON.parse(JSON.stringify(initialDataNormal));

		let itemsBrand = dataInitial["vMarca"];
		let listModel = typeof dataInitial["vModelo"]!=="undefined" ? dataInitial["vModelo"] : [];

		const index = itemsBrand.findIndex(marca => marca.id===id);

		if(index!==-1){
			
			itemsBrand[index].checked = typeof check !=="undefined" ? check : !itemsBrand[index].checked;
			itemsBrand[index].visible = typeof check !=="undefined" ? check : !itemsBrand[index].checked;

			if(itemsBrand[index].visible){
				let checkArray = listModel.filter(item => item.id === id)
				if(checkArray.length===0){
					listModel.unshift(itemsBrand[index])	
				}
				
			}else{
				listModel = listModel.filter(item => item.id!== itemsBrand[index].id);
			}

			let brandSelected = itemsBrand.filter(item => item.checked===true).map(marca => {
				return {
					label: marca.title,
					options: marca.modelos,
					id: marca.id,
					title: marca.title,
					value: marca.value,
					checked: marca.checked,
					slug: marca.slug,
					valueForm: marca.valueForm,
					name: marca.name
				}
			});

			const payload = { 
				brands: itemsBrand, 
				models: listModel,
				itemSelected: { brands: brandSelected, models: []}
			};
	
			dispatch(requestSuccess(UPDATE_SELECTED_BRAND_COMBO, payload));

			return { status: true, message: '' };
		}
		return { status:false, message: 'Error al seleccionar la marca'};
    }catch(error){
      return {status: false, message:error};
    }
};

export const getVerifyStatusModel = (item, checked) => (dispatch, getState) => {
	try{
		const { initialDataNormal, itemSelectedBrandANDModel } = getState().search;

		let dataInitial = JSON.parse(JSON.stringify(initialDataNormal));

		let models = dataInitial["vModelo"];

		let modeloSelected = itemSelectedBrandANDModel?.models;

    	const index = models.findIndex(marca => marca.id===item?.id_marca);
		if(index!==-1){
			const indexModel = models[index].modelos.findIndex(modelo => modelo.id===item?.id);
			if(indexModel!==-1){
				models[index].modelos[indexModel].visible = checked;
				models[index].modelos[indexModel].checked = checked;
			}
		}   
		
		const key = modeloSelected.findIndex(modelo => modelo.id===item?.id_marca && modelo.id===item?.id);
		if(key!==-1){
			modeloSelected[key].visible = checked;
			modeloSelected[key].checked = checked;
		} else{
			modeloSelected.push(item)
		}

    	const payload = { models, modeloSelected };

		dispatch(requestSuccess(LIST_SELECTED_MODEL_COMBO, payload));
    }catch(error){
      return {status: false, message:error};
    }
};


export const deleteItemBrandSelected = (item) => (dispatch, getState) => {

	try{
		let { initialDataNormal, itemSelectedBrandANDModel } = getState().search;

		let dataInitial = JSON.parse(JSON.stringify(initialDataNormal));

		let listadoBrandsData = dataInitial["vMarca"];
		let listModelsData = typeof dataInitial["vModelo"] !=="undefined" ? dataInitial["vModelo"] : []
		
		
		let listBrandsAll = itemSelectedBrandANDModel.brands.filter(x=>x.id!==item.id);

		const index = listadoBrandsData.findIndex(marca => marca.id===item.id);
		listadoBrandsData[index].visible = !listadoBrandsData[index].visible;
		listadoBrandsData[index].checked = !listadoBrandsData[index].checked;
		listadoBrandsData[index].modelos = listadoBrandsData[index].modelos.map(marca => {
			return {
				...marca,
				visible: false,
				checked: false
			}
		})

		let listadoModelosData = listModelsData.filter(x=>x.id!==item.id);

		let listModels = itemSelectedBrandANDModel.models.filter(x => x.id_marca!==item.id);

		const payload = { 
			items:{ brands: listBrandsAll, models: listModels}, 
			listBrands: listadoBrandsData,
			listModels: listadoModelosData
		};

		dispatch(requestSuccess(DELETE_BRAND_SELECTED_COMBO, payload));

	}catch(error){
		return {status: false, message:error};
	}
	
};

export const deleteItemModelSelected = (item) => (dispatch, getState) => {
	try{
		let { initialDataNormal, itemSelectedBrandANDModel } = getState().search;

		let dataInitial = JSON.parse(JSON.stringify(initialDataNormal));

		let listadModeloData = dataInitial["vModelo"];
	
		let listModelsAll = itemSelectedBrandANDModel.models.filter(x=>x.id!==item.id);

		const index = listadModeloData.findIndex(modelo => modelo.id===item.id_marca);

		if(index!==-1){
			const keyModel = listadModeloData[index].modelos.findIndex(modelo => modelo.id===item.id);

			if(keyModel!==-1){
				listadModeloData[index].modelos[keyModel].visible = false;
				listadModeloData[index].modelos[keyModel].checked = false;
			}
		}

		const payload = { 
			itemsModels: listModelsAll, 
			listModels: listadModeloData
		};

		dispatch(requestSuccess(DELETE_MODEL_SELECTED_COMBO, payload));

	}catch(error){
		return {status: false, message:error};
	}
	
};

export const generateItemMarcaModelForm = () => (dispatch, getState) => {

	try{
		let { initialDataNormal } = getState().search;

		let dataInitial = JSON.parse(JSON.stringify(initialDataNormal));

		let listadoMarcaData = dataInitial["vMarca"];

		listadoMarcaData = listadoMarcaData.map(item => {
			return {
				name: item.title, 
				slug: item.slug, 
				checked: item.checked
			}
		})

		let listadoModeloData = dataInitial["vModelo"];

		let listModels = []

		for(let index in listadoModeloData){

			listModels[listadoModeloData[index].slug] = []
			for(let key in listadoModeloData[index].modelos){
				listModels[listadoModeloData[index].slug].push({
					title: listadoModeloData[index].modelos[key].title,
					slug: listadoModeloData[index].modelos[key].slug,
					checked: listadoModeloData[index].modelos[key].checked,
				})
			}		
		}

		return { status: true, data: { marcas: listadoMarcaData, modelos: listModels}}
		
	}catch(error){
		return {status: false, message:error};
	}
};