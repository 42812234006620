import { useEffect } from "react"
import Layout from 'components/Layout/Layout'
import DetailCar from 'components/Route/DetailCar'
import FormContactRoute from 'components/Customs/Contact/FormContactRoute'
import ModalSpinner from 'components/Customs/Modal/ModalSpinner'

import down_arrow_left from 'assets/images/icons/down-arrow-left.svg'

import { useRoute } from 'hooks/Route/useRoute'
import { Link } from 'react-router-dom'
//import { infoMyRoute } from "data/DescripcionHead.json"

const MyRoute = () => {

	const { state, actions } = useRoute()

	const { listCarMyRoute, isLoadingMyRoute, descriptionMeta } = state
	const { selectedItemFormMyRoute, historyBack, getDataInitial } = actions

	useEffect(() => {
		getDataInitial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	

	return (
		<Layout
			classHeader="row header__white"
			hiddenColor={true}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={descriptionMeta}
			classDefaultHeader="row header-top header__white"
			classTopHeader=""			
		>
			{isLoadingMyRoute && 
				<ModalSpinner message="Espera un momento, estamos generando tu correo para que puedas vivir la experiencia de favoritos" /> }
			<div className="row bg-gradiente row-top">
				<div className="col-12 route">
					<section className="container">
						<div className="text-right pt-2">
							<div className="my-route-back" onClick={historyBack}><img src={down_arrow_left} alt="Volver" /></div>
						</div>
						<div className="route-title">
							<p className="route__title">Bienvenido a <br/> Favorito de Movicenter</p>
							<p className="route__text">¡No pierdas tiempo! Busca tus autos según tus intereses y selecciona tus favoritos para visitarlos fisicamente en Movicenter.</p>
						</div>
						<div className="route__features">
							<ul>
								<li><p className="icon__route--find">Encuéntra tu auto</p></li>
								<li><p className="icon__route--star"> Guárdalo</p></li>
								<li><p className="icon__route--visit"> Visita en Movicenter</p></li>
							</ul>
							<div className="route__image">
								<picture>
									<source media="(min-width: 768px)" srcSet="https://movicenter-web.s3.us-east-2.amazonaws.com/index/desktop/white_car_desktop.png" />
									<img src="https://movicenter-web.s3.us-east-2.amazonaws.com/index/mobile/white_car_mobile.png" alt="Favorito" />
								</picture>
							</div>
						</div>
					</section>
					<section className="d-block d-md-none">
						<div className="route__known">
							<p  className="route__text mb-3">Conoce tus favoritos</p>
							<div className="icon__route--fast_forward"></div>
							<p className="route__title mt-3">Favoritos</p>
						</div>
					</section>
				</div>
			</div>
			<section className="row">
				<div className="col-12">
					<div className="container route-info-car">
						<div className="car-selected mt-md-5">
							<div className="route-title-item">
								<p className="route-title-right">Favoritos</p>
								<p className="route-title-left">{`${listCarMyRoute.length} Resultados`}</p>
							</div>				
							{
								listCarMyRoute.map((item, key) => <DetailCar key={key} {...item} keyCar={item.key} handleSelected={selectedItemFormMyRoute} />)
							}
							{listCarMyRoute.length===0 && 
								<div className=" without_car">
									<p className="without_car__title">Aun no tienes autos agregados</p>
									<p className="without_car__subtitle">Recuerda agregar todos los autos que sean de tu interés a Favorito haciendo 
									clic en para hacer más fácil y dinámica tu visita a Movicenter</p>
									<Link to="/" className="btn btn-primary btn-primary-size">Buscar autos</Link>
								</div>}
						</div>
						<FormContactRoute 
							infoSendItem={listCarMyRoute}
							valueBtnSend={listCarMyRoute.length>0 ? false : true}
						/>
					</div>
				</div>
			</section>
		</Layout>
	)
}

export default MyRoute