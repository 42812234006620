import { useState } from 'react'
import MenuItem from "components/Layout/ListMenu/MenuItem"
import menu_white from 'assets/images/icons/menu-white.svg'
import menu_orange from 'assets/images/icons/menu-orange.svg'
import route_white from 'assets/images/my-route/auto-favorito-header.svg'
import logo_orange from 'assets/images/logo.svg'
import logo_white from 'assets/images/logo-white.svg'
import { Link } from 'react-router-dom'

import Menu from 'components/Layout/Menu'
import { useSelector } from 'react-redux'
import { listMenu } from "routes/route-main"

const Header = ({ hiddenColor, actionAdvertising, navbarClass, listMenuServicesAll }) => {

	const [ isVisibleMenu, setIsVisibleMenu ] = useState(false)
	const { listCarMyRoute } = useSelector((state) => state.cars)
	
	const visibleMenu = () => {
		setIsVisibleMenu(!isVisibleMenu)
	}

	const openActionModal = (type) => {
		setIsVisibleMenu(!isVisibleMenu)
		actionAdvertising({ action_type: type })
	}




	return (
		<div className="container">
			<div className="header">
				<div className="header__menu">
						{
							!navbarClass ?
								<img src={menu_orange} alt="Menu" onClick={()=>visibleMenu()}/>
							:
								<img src={menu_white} alt="Menu" onClick={()=>visibleMenu()}/>
						}
					
				</div>
				<div className="header__logo">
					<Link to="/" >
						{
							!navbarClass ?
								<img src={logo_orange} alt="Logo" />
							:
								<img src={logo_white} alt="Logo" />
						}
						
					</Link>
				</div>
				<nav className="menu__desktop">
					<ul className="menu__desktop--item">
						{
							listMenu.length>0 && listMenu.filter(item=> item.visibleDsk===true).map((menu, key) => 
								<MenuItem key={key} menu={menu} services={listMenuServicesAll} />
							)
						}
					  {/** 	<li><a 
								className={`menu__desktop--link btn-soap__header`}
								href="https://boffice.clicar.cl/citapisowpyapo/index?comeFrom=wp"
								target="_blank"
								rel="noreferrer"
							>Vende tu auto</a></li>*/}
					</ul>
				</nav>
				<div className="header__route">
					<Link to="/vehiculos/favoritos">
						<figure className="fig-myroute">
						  <img src={route_white} alt="Ruta" />
						</figure>
						{listCarMyRoute.length>0 && (
							<div className="number-cars">
								<p>{listCarMyRoute.length}</p>
							</div>
						)}
					</Link>
				</div>
			</div>
			{isVisibleMenu && <Menu handleClosed={visibleMenu} hiddenColor={hiddenColor} openActionModal={openActionModal} />}
		</div>
	)
}

export default Header


Header.defaultProps = {
	hiddenColor: true
}