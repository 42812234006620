import Layout from 'components/Layout/Layout'
import Ubication from 'components/Home/Initial/Ubication'
import styles from 'styles/electromobility.module.css'
import car_main from 'assets/images/electromibility/car-main.webp'
import logo_meec from 'assets/images/electromibility/logo-meec.png'
import location_map from 'assets/images/electromibility/map.png'
import logo_tercera from 'assets/images/electromibility/logo-tercera.png'
import logo_mt from 'assets/images/electromibility/logo-mt.png'
import logo_tesla from 'assets/images/electromibility/tesla.png'
import logo_fiat from 'assets/images/electromibility/fiat.png'
import logo_mg from 'assets/images/electromibility/mg.png'
import logo_peugeot from 'assets/images/electromibility/peugeot.png'
import logo_bmw from 'assets/images/electromibility/bmw.png'
import logo_mini from 'assets/images/electromibility/mini.png'
import logo_omoda from 'assets/images/electromibility/omoda.png'
import logo_chevrolet from 'assets/images/electromibility/chevrolet.png'
import logo_jmc from 'assets/images/electromibility/jmc.png'
import logo_gac from 'assets/images/electromibility/gac.png'
import logo_gogoro from 'assets/images/electromibility/gogoro.png'
import logo_renault from 'assets/images/electromibility/renault.png'
import logo_hyundai from 'assets/images/electromibility/hyundai.png'
import logo_riddara from 'assets/images/electromibility/riddara.png'
import logo_kn from 'assets/images/electromibility/kn.svg'
import logo_audi from 'assets/images/electromibility/audi.png'
import logo_anac from 'assets/images/electromibility/anac.png'
import fondo_cars from 'assets/images/electromibility/fondo.webp'


import { useElectrical } from "hooks/Electrical/useElectrical"


const HomeElectromobility = () => {
	
	const { descriptionMeta } = useElectrical()

	return (
		<Layout
			classHeader="row header__white"
			hiddenColor={true}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={descriptionMeta}
			classDefaultHeader="row header-top header__white"
			classTopHeader=""
			isVisibleWhatsapp={false}
		>
			<section className={`row ${styles["bg-gray"]}`}>
				<div className={`col-12 ${styles["col"]}`}>
					<section className={`container ${styles["container-main"]}`}>
						<div className={styles["container-main__left"]}>
							<h1 className={styles["title-h1"]}>EL FUTURO DE LA ELECTROMOVILIDAD <br/>
								COMIENZA AQUÍ </h1>
							<div className={styles["image-meec"]}>
								<img src={logo_meec} alt="MEEC" />
							</div>
							<div>
								<p className={styles["info-title"]}>Fecha: Viernes 18 a Domingo 20 de Octubre </p>
								<p className={styles["info-title"]}>Horario: 10:00 a 18:00 hrs </p>
								<p className={styles["info-title"]}>Lugar: Movicenter, Santiago </p>
								<p className={styles["info-title"]}>Entrada: Liberada</p>
							</div>
							<h3 className={styles["subtitle"]}>¡Explora la revolución eléctrica!</h3>
							<p className={styles["info-description"]}>
								Movi Electric Expo Chile (MEEC) es el evento más grande y completo de electromovilidad en el país. Descubre los avances en tecnología eléctrica, los vehículos más innovadores y las soluciones de carga que están transformando el transporte como lo conocemos.
							</p>
						</div>
						<div className={styles["container-main__right"]}>
							<div className={styles["image-dsk"]}>
								<img src={car_main} alt="¡Explora la revolución eléctrica!" />
							</div>
						</div>
					</section>
				</div>
			</section>
			<section className={`row ${styles["bg-gray"]} ${styles["container-expo__row"]}`}>
				<div className={`col-12`}>
					<section className={`container`}>
						<h3 className={styles["container-expo__subtitle"]}>¿Qué encontrarás en la Movi Electric Expo Chile?</h3>
						<div>
							<p className={styles["container-expo__description"]}>
								<b>Vehículos Eléctricos de Última Generación: </b> 
								Desde vehículos compactos hasta SUVs y camionetas, explora una gama completa de soluciones de movilidad eléctrica para todas las necesidades.
							</p>
							<p className={styles["container-expo__description"]}>
								<b>Test Drive de Nuevos Modelos: </b>
								¡Conduce los autos eléctricos más avanzados del mercado! Vive la experiencia de un test drive que te conectará con el futuro del transporte.
							</p>
							<p className={styles["container-expo__description"]}>
								<b>Charlas y Conferencias Especializadas: </b>
								Asiste a paneles con expertos nacionales e internacionales, quienes compartirán sus conocimientos sobre el futuro de la movilidad eléctrica, sostenibilidad y las innovaciones tecnológicas más recientes. 
							</p>
							<p className={styles["container-expo__description"]}>
								<b>Networking y Alianzas: </b>
								Conéctate con profesionales, marcas y empresas que lideran la transición hacia la electromovilidad. Genera alianzas estratégicas para estar a la vanguardia de esta transformación global.
							</p>
						</div>
					</section>
				</div>
			</section>
			<section className={`row ${styles["bg-photo"]}`}>
				<img src={fondo_cars} alt="Participan" className={styles["brand-background"]} />
				<div className={`col-12 ${styles["brand-col"]}`}>
					<section className={`container`}>
						<h3 className={styles["brand-title"]}>Participan</h3>
						<div>
							<p className={`${styles["brand-description"]} mb-3`}>Expositores: </p>
							<p className={`${styles["brand-description"]} mb-3`}>Varias de las marcas más importantes del mundo presentarán sus innovaciones en el evento que marcará el  futuro de la movilidad eléctrica.</p>
						</div>
						<div className={styles["brand-container"]}>
							<div className={styles["brand-logo"]}>
								<img src={logo_tesla} alt="Tesla" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_fiat} alt="Fiat" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_mg} alt="MG" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_peugeot} alt="Peugeot" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_bmw} alt="BMW" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_mini} alt="Mini" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_omoda} alt="OMODA" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_chevrolet} alt="Chevrolet" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_jmc} alt="JMC" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_gac} alt="GAC" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_gogoro} alt="Gogoro" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_renault} alt="Renault" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_hyundai} alt="Hyundai" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_riddara} alt="Riddara" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_kn} alt="Kn" />
							</div>
							<div className={styles["brand-logo"]}>
								<img src={logo_audi} alt="Audi" />
							</div>
						</div>
					</section>
				</div>
			</section>
			<section className={`row ${styles["bg-gray"]}`}>
				<div className={`col-12`}>
					<section className={`container`}>
						<h3 className={styles["location-title"]}>¿Cómo llegar?</h3>
						<div>
							<p className={`${styles["location-description"]} mb-0`}>Ubicación: </p>
							<p className={`${styles["location-description"]} mb-3`}>Av. Américo Vespucio Norte 1155, Huechuraba - Movicenter, Santiago. </p>
							<p className={`${styles["location-description"]} mb-3`}>Estacionamiento: Gratis para asistentes, con estaciones de carga para vehículos eléctricos disponibles en el lugar. </p>
							<p className={`${styles["location-description"]} mb-3`}>Entrada: Liberada para todos los asistentes.</p>
						</div>
						<div className={styles["location-container"]}>
							<h3 className={styles["location-container__title"]}>Plano de la expo</h3>
							<div className={styles["location-meec"]}>
								<img src={logo_meec} alt="MEEC" />
							</div>
						</div>
						<div className={styles["location-map"]}>
							<img src={location_map} alt="Mapa de Movicenter" />
						</div>
						<Ubication isClass="map-link-in" isClassTitle="d-none" />
						<div className={styles["location-visit"]}>
							<p className={styles["location-visit__title"]}>Visítanos y sé parte de la revolución eléctrica</p>
							<p className={styles["location-visit__subtitle"]}>No te pierdas la oportunidad de sumarte a la transformación que está cambiando la manera en que nos movemos. ¡Visítanos y descubre lo que el futuro de la electromovilidad tiene preparado para ti!</p>
						</div>
						<div className={styles["location-partners"]}>
							<p className={styles["location-partners__title"]}>
								MEDIA PARTNERS
							</p>
							<div className={styles["location-partners__photo_1"]}>
								<img src={logo_tercera} alt="La tercera" />
							</div>
							<div className={styles["location-partners__photo_2"]}>
								<img src={logo_mt} alt="MT" />
							</div>							
						</div>
						<div className={styles["location-partners__bottom"]}>
							<p className={styles["location-partners__title"]}>
								PATROCINA
							</p>
							<div className={styles["location-partners__photo_2"]}>
								<img src={logo_anac} alt="Anac" />
							</div>
							
						</div>
					</section>
				</div>
			</section>
		</Layout>
	);
};

export default HomeElectromobility;	