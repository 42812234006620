import { useState } from 'react'
import { useAdvertising } from 'hooks/useAdvertising';
import { getListInitialServices, searchServiceBySlug, resetServiceSelected, getServicesProviderByID } from 'services/actions/serviceActions'
import { useFormContactServiceProvider } from "hooks/Contact/useFormContactServiceProvider";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useConfig } from "hooks/MetaHead/useConfig"

export const useMain = () => { 
  const location = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { resultServices, servicesAll, serviceSelected, itemSelectedListForm, loadingCategoriesProvider,
      isDataService  } = useSelector((state) => state.services)
    const { advertisingServices, actionAdvertising, getAdvertisingInitial } = useAdvertising()

    const { handleActive, disabledBtn, register,  handleSubmit, 
		onSubmit, message, isOpen, closeModal, isLoading,
		isOpenForm, handleQuoteCar, closeModalForm, errors } = useFormContactServiceProvider()
    const { getDataMetaTag } = useConfig()
    const [ descriptionMeta, setDescriptionMeta ] = useState({})

    const getDataService = async (slug) => {
      dispatch(resetServiceSelected());
      await getAdvertisingInitial();
      
      if(servicesAll.length===0){
        await dispatch(getListInitialServices())
      }		
      await dispatch(searchServiceBySlug(slug))
    }

    const gotToBack = () => {
		history.go(-1)
	}

    const getDataServiceProvider = async (proveedorSlug) => {
      const infoMeta = await getDataMetaTag(location?.pathname)
		  setDescriptionMeta(infoMeta)
      dispatch(resetServiceSelected())
      if(servicesAll.length===0){
        await dispatch(getListInitialServices())
      }

      dispatch(getServicesProviderByID(proveedorSlug))
    }

    const selectedQuote = (event, id, name) => {
      if(name!==""){
              handleQuoteCar(id, name, "services")
      }		
    }

    const handleMoveTo = (event, idPanel) => {
      event.preventDefault()
      document.getElementById(idPanel).scrollIntoView(true)
    }

    const state = {
        resultServices,
        advertisingServices,
        serviceSelected,
        itemSelectedListForm,
        loadingCategoriesProvider,
        isDataService,
        descriptionMeta
    }

    const actions = {
        getDataService,
        gotToBack,
        actionAdvertising,
        getDataServiceProvider,
        selectedQuote,
        handleMoveTo
    }

    const formElement = {
		handleActive, disabledBtn, register,  handleSubmit, 
		onSubmit, message, isOpen, closeModal, isLoading,
		isOpenForm, handleQuoteCar, closeModalForm, errors
	}

    return {
        state,
        actions,
        formElement
    }
}