import { useEffect, useState } from "react";

import { getDataInitialBrand, getUpdateVisibleBrand, getLoadingBrand } from 'services/actions/marcaActions';
import { getListTypeMotorcycle } from 'services/actions/motorcycleAction';
import { getConfigPanel, getDataInitialSearchBrandModel, getDataInitialSearch } from 'services/actions/searchActions';
import { getListNews } from 'services/actions/blogActions'

import { useDispatch, useSelector } from 'react-redux';
import { useAdvertising } from 'hooks/useAdvertising';
import { useFeatured } from 'hooks/useFeatured';

import MetaTagNew from "hooks/MetaHead/MetaTagNew.json"
import MetaTagUsed from "hooks/MetaHead/MetaTagUsed.json"
import { useLocation } from 'react-router-dom';
import { useConfig } from "hooks/MetaHead/useConfig"

export const useHome = (nameFeaturedCar, nameHomeBrand) => {
	
	const dispatch = useDispatch()
	const location = useLocation()
	const [ metaTags, setMetaTags ] = useState([])
	const { services, marcas, providers, search, motorcycles, blog, secure } = useSelector((state) => {
		return {
			services: state.services,
			marcas: state.marcas,
			providers: state.providers,
			search: state.search,
			motorcycles: state.motorcycles,
			blog: state.blog,
			secure: state.secure
		}
	})

	const {  listBrands } = marcas
	const { isPanelSearch, listInterestNew, allBodyWorkNew, listInterestUsed, 
		allBodyWorkUsed } = search
	const { listSuppliers, listBrandsConcessionaireNew, listBrandsConcessionaireUsed, groupListStore, 
		groupListStoreNew, groupListStoreUsed } = providers
	const { listServicesHome, listServices  } = services
	const { listTypeMotorcycle } = motorcycles
	const { news, listPostNews, loadingPost } = blog
	const { loadingSecure } = secure

	const { advertisingHomeMain, actionAdvertising, getAdvertisingInitial, 
		bannerSliderHome, advertisingHomeNew, advertisingHomeUsed, bannerSliderHomeNew, bannerSliderHomeUsed,
		advertisingSliderPrincipal, advertisingSliderNew, advertisingSliderUsed, getPages,
		pageDurationTimerPrincipal,  pageDurationTimerNew, pageDurationTimerUsed, loadingSlider } = useAdvertising()
	const { getLoadProvider, listFeaturedCars, getListFeatured } = useFeatured(nameFeaturedCar, false)
	const [ isOpen, setIsOpen ] = useState(false)

	const openModal = () => setIsOpen(true)

	const closeModal = () => setIsOpen(false)

	const { getDataMetaTag } = useConfig()
	const [ descriptionMeta, setDescriptionMeta ] = useState({})

	const getDataInitial = async () => {
		
		const infoMeta = await getDataMetaTag(location?.pathname)
		setDescriptionMeta(infoMeta)

		dispatch(getDataInitialSearch(nameHomeBrand))

		await getPages();

		await getAdvertisingInitial();

		if(location?.pathname==="/"){
			openModal()
		}

		if(listBrands.length===0){
			await dispatch(getDataInitialBrand());
		}

		dispatch(getUpdateVisibleBrand(nameHomeBrand));

		dispatch(getDataInitialSearchBrandModel())
		
		if(listSuppliers.length===0){
			await getLoadProvider();
		}

		if(listTypeMotorcycle.length===0 && nameFeaturedCar==="car-home"){
			await dispatch(getListTypeMotorcycle())
		}

		let metaTagsData = []
		if(MetaTagUsed.list.length > 0){
			metaTagsData = [ ...metaTagsData, ...MetaTagUsed.list ]
		}
		if(MetaTagNew.list.length > 0){
			metaTagsData = [ ...metaTagsData, ...MetaTagNew.list ]
		}
		setMetaTags(metaTagsData)

		if(news.length===0){
			await dispatch(getListNews())
		}

		await getListFeatured(nameFeaturedCar)	
		
		
	};

	useEffect(() => {
		dispatch(getLoadingBrand())

		if(loadingSecure===false){
			getDataInitial()
		}		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingSecure])

	const changeSearch = () => {
	 	dispatch(getConfigPanel(false))	
	}

	const handleClickModal = (imagens) => {
		if(imagens?.action_type!=="url" && imagens?.action_type!=="N/A"){
			closeModal()
			actionAdvertising(imagens)
		}		
	}

	const state = {
		advertisingHomeMain,
		bannerSliderHome,
		isPanelSearch,
		listSuppliers,
		listFeaturedCars,
		listServicesHome, 
		listServices,
		listTypeMotorcycle,
		isOpen,
		advertisingHomeNew,
		listInterestNew, 
		allBodyWorkNew,
		listBrandsConcessionaireNew,
		advertisingHomeUsed,
		listInterestUsed, 
		allBodyWorkUsed,
		listBrandsConcessionaireUsed,
		news, 
		listPostNews,
		loadingPost,
		location,
		groupListStore,
		groupListStoreNew, 
		groupListStoreUsed,
		metaTags,
		bannerSliderHomeUsed, 
		bannerSliderHomeNew,
		advertisingSliderPrincipal,
		advertisingSliderNew,
		advertisingSliderUsed,
		pageDurationTimerPrincipal, 
		pageDurationTimerNew, 
		pageDurationTimerUsed,
		loadingSlider,
		descriptionMeta
	}

	const routePathCurrent = location?.pathname

	const actions = {
		changeSearch,
		actionAdvertising,
		closeModal,
		handleClickModal
	}


	return {
		state,
		routePathCurrent,
		actions
	};
};
	