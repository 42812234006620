import { useEffect } from 'react'
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import Layout from 'components/Layout/Layout'
import BannerEvent from 'components/Customs/Banner/Horizontal/BannerEvent'
//import { homeInitialCar } from "data/DescripcionHead.json"
import styles from 'styles/offer.module.css'
import { useAdvertising } from 'hooks/useAdvertising'
import { useOffer } from 'hooks/Offer/useOffer'
import NotificationForm from 'components/Customs/Modal/NotificationForm';
import ModalForm from 'components/Result/ModalForm'

const HomeOfertas = () => {

    const { actionAdvertising } = useAdvertising()
    const { listOfferAll, getDataInitial, formElement, itemSelectedListForm, offerMain, loadingOffer, title, descriptionMeta } = useOffer()

    const { handleActive, disabledBtn, register,  handleSubmit, onSubmit, message, isOpen, closeModal, isLoading,
		isOpenForm, closeModalForm, errors, setFormToken, getSelectOffer } = formElement

    useEffect(() => {
        getDataInitial()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Layout
            classHeader=""
            hiddenColor={false}
            isVisibleFooter={true}
            visibleLine={true}
            infoMetaHead={descriptionMeta}
            classDefaultHeader="row header-top"
            classTopHeader="header__white"
            classMenuHeader="background-top-white"
            typePage={true}
        >
            <div className={`row row-top ${styles["background-all"]}`} >
                <div className="col-12">
                    {
                        (!loadingOffer && offerMain!==null) && 
                        <BannerEvent 
                            imagen={offerMain}
                            functionModal={actionAdvertising}
                            title="Banner" 
                            classContainer="banner__financing" 
                            classPicture="banner__top_mobile--35"
                        />
                    }
                    {
                        loadingOffer && (
                            <div className={`row`}>
                                <div className="col-12">
                                    <div className="container">
                                        <Skeleton height={201}  />
                                    </div>						
                                </div>
                            </div>
                        )
                    }
                    <div className="container">
                        <h1 className={styles?.title}>Ofertas de {title}</h1>
                        <section className={styles["container-offer"]}>
                            {
                                (!loadingOffer && listOfferAll?.length>0) &&
                                listOfferAll.map(item =>
                                    <div className={styles["item-offer"]} key={item?.id}>
                                        <div className={styles["item-offer__photo"]}>
                                            <img src={item?.ic_desktop_main} alt="" />
                                        </div>
                                        <div className={styles["item-offer__body"]}>
                                            <div className={styles["item-offer__info"]}>
                                                <div>
                                                    <p className={styles["item-offer__category"]}>{item?.category}</p>
                                                    <p className={styles["item-offer__brand"]}>{item?.title}</p>
                                                </div>
                                                {
                                                    item?.brand && (
                                                        <div className="info-car__marca">
                                                            <img src={item?.brand?.icon} alt={item?.brand?.name} />
                                                        </div>
                                                    )
                                                }
                                                
                                            </div>
                                            <div className={styles[item?.description ? "item-offer__description" : "item-empty__description"]}>
                                                <p>{item?.description}</p>
                                            </div>
                                            <button 
                                                className={`btn btn-outline-danger ${styles["btn-send"]}`}
                                                onClick={() => getSelectOffer(item)}
                                            >Cotizar</button>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                loadingOffer && 
                                Array.from([1, 2, 3]).map((item, key) => (
                                    <div className={styles["item-offer"]} key={key}>
                                        <div className={styles["item-offer__photo"]}>
                                            <Skeleton height={150}  />
                                        </div>
                                        <div className={styles["item-offer__body"]}>
                                            <Skeleton  height={10}className={styles["item-offer__category"]} />
                                            <Skeleton  height={10} className={styles["item-offer__brand"]} />
                                            <Skeleton height={50} className="mt-2"  />
                                        </div>
                                    </div>
                                ))
                            }                            
                        </section>
                    </div>
                    
                </div>
            </div>
            {isOpenForm && <ModalForm 
				show={isOpenForm} 
				handleClose={closeModalForm} 
				register={register} 
				handleSubmit={handleSubmit} 
				onSubmit={onSubmit}
				isLoading={isLoading}
				infoSendItem={itemSelectedListForm}
				handleActive={handleActive}
				disabledBtn={disabledBtn}
				provider={itemSelectedListForm}
				errors={errors}
				setFormToken={setFormToken}
				checkValue={false}
			/>}
            {isOpen && <NotificationForm 
					show={isOpen} 
					handleClose={closeModal} 
					message={message}
				/>
			}
            
        </Layout>
    )

}

export default HomeOfertas