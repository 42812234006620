import { useEffect, useState } from 'react'
//import ReactGA from 'react-ga'
import Layout from 'components/Layout/Layout'
import { Link, useLocation } from 'react-router-dom';
import { getListNews } from 'services/actions/blogActions'
import { useDispatch, useSelector } from 'react-redux'
import PostCards from 'components/Blog/PostCards'
//import { homeBlog } from "data/DescripcionHead.json"
import { useConfig } from "hooks/MetaHead/useConfig"


const Posts = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { listViewBlog, lastPost  } = useSelector((state) => state.blog)

    const [ descriptionMeta, setDescriptionMeta ] = useState({})

    const { getDataMetaTag } = useConfig()

    const getListPosts = async () =>{
        const infoMeta = await getDataMetaTag(location?.pathname)
		setDescriptionMeta(infoMeta)
        if(listViewBlog.length===0){
            await dispatch(getListNews())
        }
    }

    useEffect(() => {
        getListPosts()
        //ReactGA.pageview(window.location.pathname)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Layout
            classHeader="row header__white"
            hiddenColor={true}
            isVisibleFooter={true}
            visibleLine={true}
            infoMetaHead={descriptionMeta}
            classDefaultHeader="row header-top header__white"
			classTopHeader=""
        >
            <div className="row">
            <div className="container-main--img">
                <img alt={lastPost?.title?.rendered} className="blog__content__main container-main--img__first" src={lastPost?.acf?.imagen_de_cabecera?.sizes?.large}/>
            </div>
                <div className="col-12 p-absolute__post">
                    <div className="container main main--top--blog pl-0 pr-0">                      
                        {
                            Object.keys(lastPost).length>0 &&  (
                                <div  className="last__post__container">
                                    <div className="last__post">
                                        <p className="last__post__title">{lastPost.title.rendered}</p>
                                        <p className="last__post__description">{lastPost.acf.descripcion}</p>
                                        <p className="blog__preview__date"><span className="mr-3">{lastPost.formatted_date}</span></p>
                                        <div className="blog__button">
                                            <Link to={`/blog/${lastPost.slug}`} className="btn btn-primary btn-lg btn-reed-article">Leer artículo</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            <div className="row row-last">
                <div className="col-12">
                    <div className="container">
                        <h1 className="mb-1 title-posts">Artículos anteriores</h1>
                        <hr className="line__article mb-5" />
                        <div className="blog-row">
                            {
                                listViewBlog.map((post, key) => (
                                    <PostCards key={key} {...post} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            
        </Layout>
    )

}

export default Posts