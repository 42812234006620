import API from 'services/context/api'
import { 
    LIST_OFFER,
	 LOADING_OFFER
} from 'services/types/offerType'
import endPoints from 'services/routes/';
import { TOKEN_MAINTANER } from "data/variableKey";


export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const getListOffer = () => async (dispatch, getState) => {
	try{
		const { listSuppliers } = getState().providers;

		let response = await API.getApi({
								endPoint: endPoints.offer.getList, 
								nameAPI: TOKEN_MAINTANER, 
								isToken: true,
								isFormData: false
							})
		
		const { data, isSuccessFull } = response.data
		
		let offerMain = null;
		let listNewOffer = [];

		if(data!==null){
			
			data.sort((a, b) => {
				return  a.ordr - b.ordr;
			})

			const listUpdateOffer = [...data].map(item => {
				 return {
					 ...item?.data,
					 id: item?.id,
				 }
			})
			const main = listUpdateOffer.find(item => item?.type==="banner")

			offerMain = {
				id: main?.id,
				type: main?.type,
				ic_desktop_main: main?.ic_desktop_main,
				ic_mobile_main: main?.ic_mobile_main,
				url_banner: "",
				start_date: "",
				is_banner_hidden: false,
				action_type: "N/A",
				end_date: "",
				is_scheduled: "false",
				ic_desktop_scheduled: "",
				ic_mobile_scheduled: "",
				order: 1,
				proxDate: false
			}

			listNewOffer =listUpdateOffer.filter(item => item?.type==="offer")
			for(let index in listNewOffer){
				const brand = listSuppliers?.find(brand => brand.name === listNewOffer[index].title)
				listNewOffer[index].brand = brand
			}

			/*let groupByOffer = {};

			for(let index in filterOffer){
				if(!groupByOffer.hasOwnProperty(filterOffer[index]?.title)){
					groupByOffer[filterOffer[index]?.title]=[];
				}
				groupByOffer[filterOffer[index]?.title].push(filterOffer[index]);
			}**/
		
			/**let keysAll = [];

			for(let [key, offer] of Object.entries(groupByOffer)){
				keysAll.push(key)
				listNewOffer = [ ...listNewOffer, ...offer]
			}**/
		 }

		let payload = { 
			offerMain,
			listOffer: listNewOffer
		}
	
		dispatch(requestSuccess(LIST_OFFER, payload))

		return { status: isSuccessFull }
    }catch(error){
      return {status: false, message:error}
    }
}

export const getLoadingOffer = (payload) => async (dispatch, getState) => {
	dispatch(requestSuccess(LOADING_OFFER, payload))
}