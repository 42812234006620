import { useRef, useEffect, useState } from 'react'
import Layout from 'components/Layout/Layout'
import Breadcrumb from 'components/Customs/Breadcrumb/index'
import styles from 'styles/car-moto.module.css'
import CarouselThumb from 'components/CarFile/CarouselThumb'
import FormContactGlobal from 'components/Customs/Contact/FormContactGlobal'
import BannerEvent from 'components/Customs/Banner/Horizontal/BannerEvent'
import Services from 'components/CarFile/Services/Services'
import PanelTypeTransportation from 'components/Customs/Information/PanelTypeTransportation'
import FeaturesTypeTransportation from 'components/Customs/Information/FeaturesTypeTransportation'
import MapFileCar from 'components/Customs/Mapa/MapFileCar'
import BannerUpRight from 'components/Customs/Banner/Vertical/BannerUpRight'
import Blog from 'components/Blog/Blog'
import Featured from 'components/Motorcycle/Carrousel/Featured'
import ModalSpinnerMotorcycle from 'components/Customs/Modal/ModalSpinnerMotorcycle'

import MediaQuery from 'react-responsive'

import { useFormContact } from 'hooks/FormContact/useFormContact'
import { useAdvertising } from 'hooks/useAdvertising'
import { useMotorcycle } from 'hooks/Motorcycle/useMotorcycle'
import { useParams, useLocation } from 'react-router-dom'
import useBreadcrumb from "hooks/Main/useBreadcrumb"
import { useConfig } from "hooks/MetaHead/useConfig"

const DetailMoto = () => {
	const location = useLocation()
	const { key } = useParams()
	const mapVector = useRef();
	const { advertisingPageMoto, actionAdvertising } = useAdvertising()
	const { isLoading } = useFormContact()
	const { featuredMoto, infoMotorcycle, isLoadingMoto, news, loadingPost } = useMotorcycle(key)
	const { getInfoBreadcrumb, itemsBreadcrumb , pageLast} = useBreadcrumb()
	const { getDataMetaTag } = useConfig()
	const [ descriptionMeta, setDescriptionMeta ] = useState({})

	const getDataInitial = async () => {
		const infoMeta = await getDataMetaTag(location?.pathname)
		setDescriptionMeta(infoMeta)
		//const store = typeof location?.state?.store !=="undefined" ? location?.state?.store : null
		if(Object.keys(infoMotorcycle).length>0 && infoMotorcycle?.title!=="undefined" && infoMotorcycle?.title!==""){
			getInfoBreadcrumb(infoMotorcycle?.title)
		}			
	}

	//Funcionalidad del menú miga de pan
	useEffect(() => {
		getDataInitial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [infoMotorcycle?.title])

	return (
		<Layout
			classHeader="row header__white"
			hiddenColor={true}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={descriptionMeta}
			classDefaultHeader="row header-top header__white"
			classTopHeader=""
		>
			<Breadcrumb 
				itemBreadcrumb={itemsBreadcrumb}
				isVisible={true}
				urlReturn={pageLast}
			/>
		{(isLoading || isLoadingMoto) && 
				<ModalSpinnerMotorcycle message="Espera un momento, estamos generando la información" /> }

		{!isLoadingMoto && Object.keys(infoMotorcycle).length>0 && (
			<>
				<section className="row">
					<div className="col-12">
						<div className={`container`}>
							<div className="container-flex">
								<section className={styles["carousel-initial"]}>
									
									<h1 className={styles["title-h1"]}>{infoMotorcycle?.title}</h1>
									{/**<CarouselThumb 
										id={infoMotorcycle?.id}
										isMyRouter={infoMotorcycle?.isMyRouter}
										photos={infoMotorcycle?.photos}
									>
									{
										infoMotorcycle?.photos?.length>0 &&
										infoMotorcycle?.photos?.map((item, key) => (
											<SwiperSlide key={key}>
												<img src={item} alt="Imagen 1" />
											</SwiperSlide>
										))
									}
									</CarouselThumb>**/}
									<CarouselThumb 
											photos={infoMotorcycle?.photos}
											id={infoMotorcycle?.id}
											isMyRouter={infoMotorcycle?.isMyRouter}
											idKey={infoMotorcycle?.id}
											title={infoMotorcycle?.title}
											type="motos"
										/>
									<div className="card card__container border-0 panel-detail__car">
										<div className="card__body padding-body">
											{ Object.keys(infoMotorcycle).length>0 &&
											<PanelTypeTransportation {...infoMotorcycle} /> }
										</div>
									</div>
								</section>
								<section className={styles["form-contact"]}>
									<FormContactGlobal 
										infoSendItem={infoMotorcycle}
										styleForm={{
											title: "form-custom__title",
											group: "form-car__group",
											input: "form-car__input",
											labelCheck: "form-car--label",
											groupBtn: "group-contact__car",
											main: "form-contact__car"
										}}
										stock={true}
									/>
								</section>
							</div>
						</div>
					</div>
				</section>
				<section className="row">
					<div className="col-12">
						<div className={`container ${styles["container-featured"]}`}>
							<section className={styles["featured"]}>
								{ Object.keys(infoMotorcycle).length>0 && <FeaturesTypeTransportation {...infoMotorcycle} /> }
							</section>
							<section className={styles["banner-map"]}>
								{advertisingPageMoto?.length>0 &&
									<>
										<BannerEvent 
											imagen={advertisingPageMoto[0]}
											functionModal={actionAdvertising}
											title="Banner" 
											classContainer="mb-0 mb-md-3 quote-seller__img" 
											classPicture=""
										/>
										<BannerEvent 
											imagen={advertisingPageMoto[1]}
											functionModal={actionAdvertising}
											title="Banner" 
											classContainer="mb-0 mb-md-3 quote-seller__img" 
											classPicture=""
										/>
										<BannerEvent 
											imagen={advertisingPageMoto[2]}
											functionModal={actionAdvertising}
											title="Banner" 
											classContainer="mb-0 mb-md-3 quote-seller__img" 
											classPicture=""
										/>
									</>
								}

								<MapFileCar localMap={infoMotorcycle?.infoBrand?.localMap} mapVector={mapVector} />
								<MediaQuery minWidth={768}>
									{advertisingPageMoto.length>0 &&
										 <BannerUpRight 
										 	imagen={advertisingPageMoto[3]}
											title="Banner"
											functionModal={actionAdvertising}
										/>
									}
								</MediaQuery>
							</section>
						</div>
					</div>
				</section>

				<Services 
					functionModal={actionAdvertising} 
					type="moto"
					visibleSimulador={false}
				/>

				{advertisingPageMoto.length>0 &&
					<BannerEvent 
						imagen={advertisingPageMoto[4]}
						functionModal={actionAdvertising}
						title="Banner" 
						classContainer="car-banner" 
						classPicture="car-banner__detail"
					/>
				}

				<div className={`${styles["bg-f0f0f0"]} row`}>
					<div className={`col-12 ${styles["col-featured"]}`}>
						<div className={`container ${styles["container-recommend"]}`}>
							<h4 className={styles["featured-title"]}>Recomendaciones</h4>
							<Featured 
								classSlide="featured-moto__slide"
								visibleNavigation={true}
								options={featuredMoto}
							/>
						</div>
					</div>
				</div>

				<Blog 
					title="Conoce nuestro" 
					subtitle="Blog" 
					list={news} 
					isLoop={true} 
					loading={loadingPost} 
					classCarousel="services-blog"
					titleButton="Ir al blog"
				/>
			</>
		)}
		</Layout>

	)
}

export default DetailMoto