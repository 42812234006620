import { useEffect, useState } from 'react'
import BaseLayoutStore from 'components/Layout/BaseLayoutStore'
import FormContactStore from 'components/Customs/Contact/FormContactStore'
import ModalSpinner from 'components/Customs/Modal/ModalSpinner'
import ItemResult from 'components/Concessionaire/ItemResult';
import styles from "styles/store.module.css"

import compare_quote from 'assets/images/icon/compare-quote.svg'
import order_store from 'assets/images/order-store.svg'

import { useParams, useLocation } from 'react-router-dom'
import { useConfig } from "hooks/MetaHead/useConfig"
import { useFunctionBaseConcesionaire } from "hooks/Concesionaire/useFunctionBaseConcesionaire";


const Home = () => {

	const location = useLocation()
	const { slug } = useParams()
	
	const [ descriptionMeta, setDescriptionMeta ] = useState({})

    const { actions, state } = useFunctionBaseConcesionaire()

    const { detailConcessionare, listCarsAssign, carSelectedQuote, isLoadingSearch, listModels, textModel,menuSort } = state

    const  { getDataInitialHome, selectedQuote, handleMoveTo, setTextModel, searchListCarByFilter, updateOrder } = actions
	
	const { getDataMetaTag } = useConfig()
	
	const getDataInitial = async (slug) => {
		const infoMeta = await getDataMetaTag(location?.pathname)
		setDescriptionMeta(infoMeta)
		//const store = typeof location?.state?.store !=="undefined" ? location?.state?.store : null
		await getDataInitialHome(slug)		
	}

	useEffect(() => {
		getDataInitial(slug)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slug])

	return (
		<BaseLayoutStore
			infoMetaHead={descriptionMeta}
			infoHeader={detailConcessionare} 
			classStyle={{
				classTitle: "header-separator"
			}}
			isVisibleIcon={true} 
			handleMoveTo={handleMoveTo}
		>				
		<div className={`${styles["bg-result-item"]} ${styles["bg-result-top"]}`}>
			<div className="container">
				<div className="row">
					<div className="col-12 text-left text-md-center">
						<p className={`${styles["title-search"]} pl-4`}>Encuentra tu vehículo</p>
						<form className={styles["form-search"]}>
							<select 
								id="modelId" 
								className={`${styles["form-search__select"]}`}
								onChange={(event)  => setTextModel(event.target.value)}
								defaultValue={textModel}
							>
								<option value="-">Todos los Modelos</option>
								{
									listModels.map((item, key) => 
										<option key={key} value={item}>{item}</option>
									)
								}
							</select>
							<button 
								type="button" 
								className={`btn btn-primary ${styles["btn-search"]}`}
								onClick={(event)=>searchListCarByFilter(event)}
							>
								Buscar
							</button>
						</form>
					</div>
				</div>
				<div className="row">
					{
						 listCarsAssign.length=== 0 && (
							<div className="col-12">
								<div className={styles["container-title-search"]}>
									<p className={styles["title-search__h1"]}>No hay resultados</p>
								</div>
							</div>
						 )
					}
					{
						listCarsAssign.length> 0 && (
							<div className="col-12">
								<div className={styles["container-title-search"]}>
									<p className={styles["title-search__count"]}>{listCarsAssign.length} Resultados</p>
									<div className={styles["options-search"]}>
										<img 
											src={order_store} 
											alt="Ordenar por precio" 
											onClick={()=>updateOrder()} 
											className={`${styles["options-search--icon"]} ${styles[menuSort ? "options-search--icon-bottom" : "options-search--icon-top"]}`} 
										/>
										<p 
											className={`${styles["title-search__count"]} ${styles["title-search__order"]}`}
											onClick={()=>updateOrder()} 
										>Ordenar</p>
									</div>
								</div>
								<div className={styles["result-items"]}>
									{
										listCarsAssign.length>0 &&
										listCarsAssign.map((item, key) => 
											<ItemResult 
												key={key} 
												{...item} 
												keyCar={item.key}
												selectedQuote={selectedQuote} 
												lastPage={{
													prevPath: location?.pathname,
													name: detailConcessionare?.name
												}}
											/>
										)
									}
								</div>
							</div>
						)
					}
					
				</div>
			</div>
		</div>
			
		<FormContactStore 
				isVisibleVIN={false} 
				itemService={carSelectedQuote}
				styleForm={{main:"service-form-contact"}}
				provider={detailConcessionare?.name}
			/>

			{carSelectedQuote.length>0 && (
				<div onClick={(event)=>handleMoveTo(event, "form")}>
					<div className={styles["widget-foooter"]}>
						<div className={styles["widget-foooter-buttom"]}>
							<div className="container w-90">
								<button 
									type="button" 
									className="btn btn-primary btn-lg w-100" 
									href="#form"
								>{`Cotizar (${carSelectedQuote?.length})`}</button>
							</div>
						</div>
					</div>
					<div>
						<section className={styles["widget-container"]}>
							<div className={styles["widget-container-text"]}>
								<img src={compare_quote} alt="Cotizas tus autos" className={styles["widget-container-image"]} />
								<p className={styles["widget-container-subtitle"]}>{`Cotiza tus autos (${carSelectedQuote?.length})`}</p>
							</div>
						</section>
					</div>
				</div>
			)}

			{isLoadingSearch.status && 
				<ModalSpinner 
						message={isLoadingSearch.message}
					/>
			}
		</BaseLayoutStore>
	)
}

export default Home