import API from 'services/context/api'
import { 
	LIST_SCOOTER_ELECTRICAL,
    LIST_BICYCLE_ELECTRICAL,
    LOADING_ALL_ELECTRICAL,
    SELECTED_ITEM_ELECTRICAL,
    LIST_FEATURED_ELECTRICAL
} from 'services/types/electricalType'
import { TOKEN_SITE_WEB } from "data/variableKey";


import endPoints from 'services/routes/';
import { formatDataScooter, formatDataBicycle } from 'utils/ElectricalFunction/tool'


export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const getListScooterElectrical = () => async (dispatch, getState) => {
    try{

        const { categoryProviders } = getState().services;

        let response = await API.getApi({
                                    endPoint: endPoints.electrical.listScooter, 
                                    nameAPI: TOKEN_SITE_WEB, 
                                    isToken: true,
                                    isFormData: false
                                });

        const { data } = response.data

        const category = categoryProviders.find(item => item.name==="Scooter y Bicicleta")

    	const payload = { 
            listScooter: data.map(item => {
                return formatDataScooter(item, category?.id)
            }),
            isLoadingScooter: false
        };

		dispatch(requestSuccess(LIST_SCOOTER_ELECTRICAL, payload));
		
	}catch(error){
      	return { status: false, message: error };
    }
};

export const loadingElectrical = () => async (dispatch, getState) => {
    const payload = {
        isLoadingScooter: true,
        isLoadingBicycle: true,
        isLoadingFeatured: true
    }
    dispatch(requestSuccess(LOADING_ALL_ELECTRICAL, payload));
};


export const getListBicycleElectrical = () => async (dispatch, getState) => {
    try{

        const { categoryProviders } = getState().services;

        let response = await API.getApi({
                                    endPoint: endPoints.electrical.listBicycle, 
                                    nameAPI: TOKEN_SITE_WEB, 
                                    isToken: true,
                                    isFormData: false
                                });

        const { data } = response.data

        const category = categoryProviders.find(item => item.name==="Scooter y Bicicleta")

        const payload = { 
            listBicycle: data.map(item => {
                return formatDataBicycle(item, category?.id)
            }),
            isLoadingBicycle: false
        };

        dispatch(requestSuccess(LIST_BICYCLE_ELECTRICAL, payload));
        
    }catch(error){
        return { status: false, message: error };
    }
};

export const getSelectedElectrical = (body, type) => async (dispatch, getState) => {
    try{
        const payload = {
            selected: body,
            type
        }
        
        dispatch(requestSuccess(SELECTED_ITEM_ELECTRICAL, payload));
        
    }catch(error){
        return { status: false, message: error };
    }
};

export const getListFeaturedElectrical = () => async (dispatch, getState) => {
    try{

        const { categoryProviders } = getState().services;
        
        let response = await API.getApi({
                                endPoint: endPoints.electrical.getFeatured, 
                                nameAPI: TOKEN_SITE_WEB, 
                                isToken: true,
                                isFormData: false
                            });

        const category = categoryProviders.find(item => item.name==="Scooter y Bicicleta")

        const payload = { 
            features: response.data?.data.map(item => {
                if(item.data.type==="scooter"){
                    return formatDataScooter(item, category?.id)
                }
                return formatDataBicycle(item, category?.id)
            }), 
            isLoadingFeatured: false
        };

        dispatch(requestSuccess(LIST_FEATURED_ELECTRICAL, payload));
        
    }catch(error){
        return { status: false, message: error };
    }
};