import { useEffect, useState } from 'react'
import Layout from 'components/Layout/Layout'
import ItemService from 'components/Services/ResultSearchService/ItemService'
import ModalSpinner from 'components/Customs/Modal/ModalSpinner'

import { useParams, useLocation } from 'react-router-dom'


import imgMobile from 'assets/images/background/services.png'
import imgDsk from 'assets/images/background/home_service_desktop.jpg'


import { useConfig } from "hooks/MetaHead/useConfig"
import { useAuthorizedStores } from 'hooks/AuthorizedStores/useAuthorizedStores'

const Detail = () => {

	const { slug } = useParams()
	const location = useLocation()
    const { actions, state } = useAuthorizedStores();
    const { getInitialList } = actions;
    const { listProviderAutorized, isLoading } = state;

	const { getDataMetaTag } = useConfig()
	const [ descriptionMeta, setDescriptionMeta ] = useState({})
	
    const getDataInitial = async () => {
		const infoMeta = await getDataMetaTag(location?.pathname)
		setDescriptionMeta(infoMeta)
        getInitialList();	
	}

	useEffect(() => {
        getDataInitial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slug])



	return (
		<Layout
			classHeader="row header__white"
			hiddenColor={true}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={descriptionMeta}
			classDefaultHeader="row header-top header__white"
			classTopHeader=""
		>
            <section className="row tires__template--default">
                <div className="header-tires">
                    <div className="slider-tires">
                        <div className="slider-tires__image">
                            <picture>
                                <source media="(min-width: 600px)" srcSet={imgDsk} />
                                <img 
                                    src={imgMobile}
                                    width="100%"
                                    alt="Movicenter"
                                    className="header-tires__img"
                                />
                            </picture>
                        </div>
                    </div>
                </div>
            </section>
            <div className="row">
                <div className="col-12">
                    <div className="container d__header">
                        <h1 className="services__title__authorized">Servicios Autorizados</h1>
                    </div>
                    {isLoading && <ModalSpinner message="Espera un momento, estamos buscando los servicios" />}	
                
                    <div className="row bg-gray">
                        <div className="col-12">
                            <div className="container services-container">
                                <p className="services-container--store">Tiendas autorizadas</p>
                                <div className="services-autorized">
                                    {
                                        listProviderAutorized.length>0 && listProviderAutorized.map((item, key) => 
                                                <ItemService
                                                    key={key}
                                                    title={item.name}
                                                    imagen={item.cover_image_mobile}
                                                    url={`/servicios-autorizados/${item.slug}`}
                                                    lastPage={{
                                                        prevPath: location?.pathname,
                                                        name: "Servicios Autorizados"
                                                    }}
                                                />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
        
                </div>
            </div>

			

			
		</Layout>
	)
}

export default Detail