import { useEffect, useState } from 'react'
import Layout from 'components/Layout/Layout'
import ItemService from 'components/Services/ResultSearchService/ItemService'
import ModalSpinner from 'components/Customs/Modal/ModalSpinner'
import { useLocation } from 'react-router-dom'
import imgMobile from 'assets/images/vende-tu-auto-mobile.png'
import imgDsk from 'assets/images/vende-tu-auto-desktop.png'
import { useConfig } from "hooks/MetaHead/useConfig"
import { useMain } from "hooks/Service/useMain"

const HomeSellYourCar = () => {

	const location = useLocation()

	const { getDataMetaTag } = useConfig()
	const [ descriptionMeta, setDescriptionMeta ] = useState({})

	const { state, actions } = useMain()

	const { resultServices } = state
	const { getDataService } = actions 

    const getDataInitial = async () => {
		const infoMeta = await getDataMetaTag(location?.pathname)
		setDescriptionMeta(infoMeta)
		getDataService("vende-tu-auto")		
	}
	

	useEffect(() => {
		getDataInitial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
		<Layout
			classHeader="row header__white"
			hiddenColor={true}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={descriptionMeta}
			classDefaultHeader="row header-top header__white"
			classTopHeader=""
		>
			
			<section className="row tires__template--default">
                <div className="header-tires">
                    <div className="slider-tires">
                        <div className="slider-tires__image">
                            <picture>
                                <source media="(min-width: 600px)" srcSet={imgDsk} />
                                <img 
                                    src={imgMobile}
                                    width="100%"
                                    alt="Movicenter"
                                    className="header-tires__img"
                                />
                            </picture>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row">
                <div className="col-12">
                    <div className="container d__header">
                        <h1 className="services__title__authorized">Vende tu auto</h1>
                    </div>
                    {resultServices.isLoading && <ModalSpinner message="Espera un momento, estamos buscando las tiendas que ofrecen este servicio" />}	
                
                    <div className="row bg-gray">
                        <div className="col-12">
                            <div className="container services-container">
                            <p className="services-container--small">{resultServices.list.length} Resultados de la búsqueda</p>
                                <div className="services-autorized">
                                    {
                                        resultServices.list.length>0 && resultServices.list.map((item, key) =>
                                                <ItemService
                                                    key={key}
                                                    title={item.name}
                                                    imagen={item.cover_image_mobile}
                                                    url={`/vende-tu-auto/${item.slug}`}
                                                    lastPage={{
                                                        prevPath: location?.pathname,
                                                        name: "Vende tu auto"
                                                    }}
                                                />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
        
                </div>
            </div>


			
		</Layout>
	)
}

export default HomeSellYourCar