import { useState } from "react"
import { selectedSendFormMyRoute } from "services/actions/carActions"
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useConfig } from "hooks/MetaHead/useConfig"

export const useRoute = () => {
	const location = useLocation()
	const dispatch = useDispatch()
    const history = useHistory()
    const { listCarMyRoute, isLoadingMyRoute } = useSelector((state) => state.cars)
    const [ descriptionMeta, setDescriptionMeta ] = useState({})
    const { getDataMetaTag } = useConfig()
	
    const selectedItemFormMyRoute = (event) => {
        let { value, checked } = event.target

       dispatch(selectedSendFormMyRoute(value, checked))
    }

    const historyBack = () => {
		history.go(-1)
	}

    const getDataInitial = async (slug) => {
		const infoMeta = await getDataMetaTag(location?.pathname)
		setDescriptionMeta(infoMeta)
	}

    const state = {
        listCarMyRoute, 
        isLoadingMyRoute,
        descriptionMeta
    }

    const actions = {
        selectedItemFormMyRoute,
        historyBack,
        getDataInitial
    }

	return {
		state,
        actions
	}
}