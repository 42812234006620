import { useEffect, useState } from "react";
import { getListScooterElectrical, getListBicycleElectrical, 
	getSelectedElectrical, getListFeaturedElectrical, loadingElectrical} from "services/actions/electricalAction";
import { getListNews } from 'services/actions/blogActions'
import { useDispatch, useSelector } from 'react-redux';
import { useAdvertising } from 'hooks/useAdvertising'
import { useFeatured } from 'hooks/useFeatured' 
import { useLocation } from 'react-router-dom'
import { useConfig } from "hooks/MetaHead/useConfig"

export const useElectrical = () => {
	const location = useLocation()
	const dispatch = useDispatch();
	const [ showDetail, setDetailModal ] = useState(false);
	const { electricals, blog } = useSelector((state) => {
			return {
				electricals: state.electricals,
				blog: state.blog
			}
		});
	const [ descriptionMeta, setDescriptionMeta ] = useState({})
	const { listScooter, isLoadingScooter, listBicycle, selectedItemElectrical,
		isLoadingBicycle, isLoadingFeatured, listFeaturedElectrical } = electricals
	const { news, loadingPost } = blog

	const { advertisingElectrical, getListAdvertising, actionAdvertising } = useAdvertising()
	const { getLoadListSuppliers } = useFeatured();
	const { getDataMetaTag } = useConfig()

	const handleView = (item, type) => {
		if(Object.keys(item).length>0){
			dispatch(getSelectedElectrical(item, type))
			setDetailModal(!showDetail)
		}
	}

	const getDataInitial = async () => {

		const infoMeta = await getDataMetaTag(location?.pathname)
		setDescriptionMeta(infoMeta)

		dispatch(loadingElectrical())

		await getListAdvertising()
		await getLoadListSuppliers()

		if(listScooter.length===0){
			await dispatch(getListScooterElectrical());
		}

		if(listBicycle.length===0){
			await dispatch(getListBicycleElectrical());
		}

		if(listFeaturedElectrical.length===0){
			await dispatch(getListFeaturedElectrical())
		}
		
		if(news.length===0){
			await dispatch(getListNews())
		}
	}

	const handleMoveTo = (event, idPanel) => {
		event.preventDefault()
		document.getElementById(idPanel).scrollIntoView(true)
	}

	useEffect(() => {
		getDataInitial();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return {
		listScooter, 
		isLoadingScooter,
		actionAdvertising,
		advertisingElectrical,
		handleMoveTo,
		listBicycle,
		showDetail,
		handleView,
		selectedItemElectrical,
		isLoadingBicycle,
		isLoadingFeatured,
		listFeaturedElectrical,
		news,
		loadingPost,
		descriptionMeta
	};
};