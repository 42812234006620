import API from 'services/context/api'
import endPoints from 'services/routes/';
import { TOKEN_MAINTANER } from "data/variableKey";
import { INITIAL_CONFIG,  PAGE_SELECTED_LAST } from 'services/types/configType';

export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const getConfigInitial = (value) => (dispatch, getState) => {
	const payload = value;

    dispatch(requestSuccess(INITIAL_CONFIG, payload));
};


export const savePageCurrent = (value) => (dispatch, getState) => {
	const payload = value;

    dispatch(requestSuccess(PAGE_SELECTED_LAST, payload));
};

export const getListMetaTags = (body) => async (dispatch, getState) => {

	try{	
		//let response = await API.get(`metadata/all`, 'API-MAINTAINER', true)
		let response = await API.postApi({
            endPoint: endPoints.metaTag.getDataURL, 
            data: body,
            nameAPI: TOKEN_MAINTANER, 
            isToken: true,
            isFormData: false
        })

		const { data } = response.data
       
		return { status: true, message: "", data }
		
	}catch(error){
      	return { status: false, message: error, data: null }
    }
}
