//import ReactGA from 'react-ga'
import Layout from 'components/Layout/Layout'
import SliderImage from 'components/Home/SliderImage'
import SliderImageLoading from 'components/Home/SliderImageLoading'
import Featured from 'components/Customs/Featured/Featured'
import ServiceCarousel from 'components/Customs/Featured/ServiceCarousel'
import Catalogy from 'components/Customs/CatalogyBrand/Catalogy'
import Search from 'components/Searching/Search'
import BannerEvent from 'components/Customs/Banner/Horizontal/BannerEvent'
import MapaAuto from 'components/Home/Used/MapaAuto'
import mapaDerco from 'assets/images/autos_nuevos.svg'
import Information from 'components/Home/New/Information'
import Blog from 'components/Blog/Blog'
//import MarqueUrlFriendly from '../../components/Home/MarqueUrlFriendly'
//import SimuladorFinanciamiento from '../../components/Home/New/SimuladorFinanciamiento'
//import { homeNewCar } from 'data/DescripcionHead.json'
//import { url_friendly_new } from "data/ListUrlFriendly.json"
import { useHome } from 'hooks/Main/useHome';


const NewCar = ()  => {

	const { state, actions, routePathCurrent } = useHome("car-new", "car-new")
	
	const { advertisingHomeNew, listBrandsConcessionaireNew, listFeaturedCars, listInterestNew, 
		allBodyWorkNew, news, loadingPost, location, groupListStoreNew, 
		advertisingSliderNew, pageDurationTimerNew, loadingSlider, descriptionMeta } = state

	const { actionAdvertising } = actions


	return (
		<Layout
			classHeader=""
			hiddenColor={false}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={descriptionMeta}
			classDefaultHeader="row header-top"
			classTopHeader="header__white"
			classMenuHeader="background-top-white"
			typePage={true}
		>
			{
					loadingSlider ? (
						<SliderImageLoading />
					) : (
						<SliderImage 
							banners={advertisingSliderNew} 
							functionModal={actionAdvertising} 
							options= {{
								className: "slider-header-home",
								navigation: false,
								pagination: advertisingSliderNew.length===1 ? false : true,
								spaceBetween: 0,
								autoplay: {
									delay: advertisingSliderNew.length===1 ? null : typeof pageDurationTimerNew !=="undefined" ? pageDurationTimerNew : 3000,
								},
								loop: advertisingSliderNew.length===1 ? false : true,
								speed: advertisingSliderNew.length===1 ? null  : 6000,
							}}  
						/>
					)
				}

		<Search 
				isClass="card-color" 
				closeVisible={true} 
				isVisibleTypeAuto={false} 
				isVisibleSearch={false}
				typeHomeSelected="car-new"
				routePathCurrent={routePathCurrent}
				lastPage={{
					prevPath: location?.pathname,
					name: "Autos Nuevos"
				}}
			/> 

		{ advertisingHomeNew?.length>0 && (
			<BannerEvent 
				imagen={advertisingHomeNew[0]}
				functionModal={actionAdvertising}
				title="Banner" 
				classContainer="mb-0 mt-4" 
				classPicture="car-banner__detail banner-main--home"
			/>
		)}

		<Featured
			classContainer = "tag_container--new"
			title = "Nuevos Destacados"
			SlideClass="card__feature--new"
			classFeatured="col_featured--used-featured"
			listado={listFeaturedCars?.list}
			classSlide="featured featured-used"
			visibleNavigation={true}
			lastPage={{
				prevPath: location?.pathname,
				name: "Autos Nuevos"
			}}
		 />

		 <Information />

		<Catalogy
			titleFilter="Filtrar por marca"
			listado={listBrandsConcessionaireNew }
			isClassTitle="catalogy__marca--default"
			isPagination={true}
			classCarousel="carousel__new"
			lastPage={{
				prevPath: location?.pathname,
				name: "Autos Nuevos"
			}}
			groupList={groupListStoreNew}
			classCol="col-12-none"
			classContainer="container-none"
		>
			<div className="catoly-title">
				<p className={`panel__title`}>Conoce las marcas que están en <span className="panel__title--orange">Movicenter</span></p>
			</div>
		</Catalogy>

		<ServiceCarousel 
			title="Encuentra el auto que se ajuste a tus "
			spanTitle="intereses"
			listado={listInterestNew}
			classColor="bg-white"
			classContainer="service__container--used"
			classTitle="container__interest__title--new service--used"
			lastPage={{
				prevPath: location?.pathname,
				name: "Autos Nuevos"
			}}
		/>

		{ advertisingHomeNew?.length>0 && (
			<BannerEvent 
				imagen={advertisingHomeNew[1]}
				functionModal={actionAdvertising}
				title="Banner" 
				classContainer="mb-5 mt-0" 
				classPicture="car-banner__detail"
			/>
		)}

		<MapaAuto 
			imagen={mapaDerco}
			title="Derco"
		>
			<p className="panel__title panel__title--new panel__title--map">Encuentra el <span className="panel__title--orange">auto nuevo</span> que buscas en Movicenter</p>
		</MapaAuto>

		<ServiceCarousel 
			title="Busca tu auto por tipo de  "
			spanTitle="carrocería"
			listado={allBodyWorkNew}
			classColor="bg-white"
			classContainer="service__container--used"
			classTitle="container__interest__title--used service--used container__interest--carroceria--new "
			lastPage={{
				prevPath: location?.pathname,
				name: "Autos Nuevos"
			}}
		/>

		{/**<SimuladorFinanciamiento isClass="bg-gray" />**/}

		{ advertisingHomeNew?.length>0 && (
			<BannerEvent 
				imagen={advertisingHomeNew[2]}
				functionModal={actionAdvertising}
				title="Banner" 
				classContainer="mb-5 mt-0" 
				classPicture="car-banner__detail"
			/>
		)}

		{/** url_friendly_new.length>0 && <MarqueUrlFriendly  url_friendly={url_friendly_new} />**/}

		<Blog 
			isClass="mt-5" 
			title="Conoce nuestro" 
			subtitle="Blog" 
			list={news} 
			isLoop={true} 
			loading={loadingPost} 
			classCarousel="services-blog"
			titleButton="Ir al blog"
		/>

		</Layout>
	)
}

export default NewCar