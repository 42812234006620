import { useEffect } from 'react'
import BaseLayoutStore from 'components/Layout/BaseLayoutStore'
import NotificationForm from 'components/Customs/Modal/NotificationForm';
import ModalForm from 'components/Result/ModalForm'
import CardService from 'components/Services/Card/CardService';
import CardOtherService from 'components/Services/Card/CardOtherService';
import SkeletonCardService from 'components/Services/Card/SkeletonCardService'
import FormContactStore from 'components/Customs/Contact/FormContactStore'

import { useParams, useHistory } from 'react-router-dom'

//import { homeService } from "data/DescripcionHead.json"
import { useMain } from "hooks/Service/useMain"

const DetalleProveedor = () => {

	const { slug } = useParams()
	const history = useHistory()
	const { state, actions, formElement } = useMain()

	const { serviceSelected, itemSelectedListForm, loadingCategoriesProvider, isDataService, descriptionMeta } = state
	const { getDataServiceProvider, selectedQuote, handleMoveTo } = actions 
	const { handleActive, disabledBtn, register,  handleSubmit, onSubmit, message, isOpen, closeModal, isLoading,
		isOpenForm, closeModalForm, errors, setFormToken } = formElement
	

	if(isDataService===0){
		history.go(-1)
	}

	useEffect(() => {
		getDataServiceProvider(slug)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slug])

	return (
		<BaseLayoutStore
			infoMetaHead={descriptionMeta}
			infoHeader={serviceSelected} 
			classStyle={{
				classTitle: "header-separator"
			}}
			loading={loadingCategoriesProvider}
			isDataService={isDataService}
			isVisibleIcon={true} 
			handleMoveTo={handleMoveTo}
		>	
			<div className="row">
				<div className="col-12 service-result">
					<div className="container">
						<div className="service-result">
							<div className="service-result__title">
								<p className="service-result__h1">Servicios</p>
								{serviceSelected?.list?.length>0 && <p className="service-result__small">{serviceSelected?.list?.length} Resultados</p>}
							</div>
							
							{!loadingCategoriesProvider && serviceSelected?.list?.length=== 0 && (
								<p className="service-result__text">No hay resultados disponible</p>
							)}
								
						</div>
						<div className="service__container--default service__new_result--default">
							{(!loadingCategoriesProvider && slug!=="ferripaldi") && serviceSelected?.list?.map((item, key) => 
									<CardService 
										key={key} 
										{...item} 
										selectedQuote={selectedQuote} 
									/>
								)
							}
							{(!loadingCategoriesProvider && slug==="ferripaldi") && serviceSelected?.list?.map((item, key) => 
									<CardOtherService 
										key={key} 
										{...item} 
										selectedQuote={selectedQuote} 
									/>
								)
							}
							{loadingCategoriesProvider && Array.from([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]).map(x => 
								<SkeletonCardService  key={`item-${x}`} />)}
						</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<FormContactStore 
						isVisibleVIN={false} 
						itemService={serviceSelected}
						styleForm={{main:"service-form-contact"}}
						provider={serviceSelected?.name}
					/>
				</div>
			</div>

			{isOpenForm && <ModalForm 
				show={isOpenForm} 
				handleClose={closeModalForm} 
				register={register} 
				handleSubmit={handleSubmit} 
				onSubmit={onSubmit}
				isLoading={isLoading}
				infoSendItem={itemSelectedListForm}
				handleActive={handleActive}
				disabledBtn={disabledBtn}
				provider={serviceSelected}
				errors={errors}
				setFormToken={setFormToken}
				checkValue={false}
			/>}
            {isOpen && <NotificationForm 
					show={isOpen} 
					handleClose={closeModal} 
					message={message}
				/>
			}
		</BaseLayoutStore>
		
	)
}


export default DetalleProveedor