import { formatMonto } from 'utils/tools'

export const formatDataScooter = (scooter, categoryProviderId) => {

		let item = scooter.data

		return {
			id: scooter.id,
			title: `Scooter Eléctrico Cero ${item?.model}`,
			brand: typeof item?.brand === "undefined" ? "" : item?.brand,
			model: typeof item?.model === "undefined" ? "" : item?.model,
			price: typeof item?.price === "undefined" ? "" : formatMonto(item?.price),
			description: typeof item?.description === "undefined" ? "" : item?.description,
			photos: typeof item?.photos === "undefined" ? [] : item?.photos,
			categoryProviderId,
			characteristics: generateCharacteristics(item.characteristics),
			listFeatures: [{ title: "Vende", value: item?.brand }]
		}
}

export const formatDataBicycle = (bicycle, categoryProviderId) => {

		let item = bicycle.data

		return {
			id: bicycle.id,
			title: `Bicicleta Eléctrico Cero ${item?.model}`,
			brand: typeof item?.brand === "undefined" ? "" : item?.brand,
			model: typeof item?.model === "undefined" ? "" : item?.model,
			price: typeof item?.price === "undefined" ? "" : formatMonto(item?.price),
			description: typeof item?.description === "undefined" ? "" : item?.description,
			photos: typeof item?.photos=== "undefined" ? "" : item?.photos,
			categoryProviderId,
			characteristics: generateCharacteristics(item.characteristics),
			listFeatures: [{ title: "Vende", value: item?.brand }]
		}
}

const generateCharacteristics = (data) => {
	let characteristics = []

	if(typeof data?.earring !== "undefined"){
		characteristics.push({ title: "Aro", value: data?.earring })
	}
	if(typeof data?.tyre !== "undefined"){
		characteristics.push({ title: "Neumático", value: data?.tyre})
	}
	if(typeof data?.frame !== "undefined"){
		characteristics.push({ title: "Marco", value: data?.frame })
	}
	if(typeof data?.collapsible !== "undefined"){
		characteristics.push({ title: "Plegable", value: data?.collapsible })
	}
	if(typeof data?.changes !== "undefined"){
		characteristics.push({ title: "Components", value: data?.changes })
	}
	if(typeof data?.suspension !== "undefined"){
		characteristics.push({ title: "Cambios", value: data?.suspension })
	}
	if(typeof data?.engine !== "undefined"){
		characteristics.push({ title: "Suspensión", value: data?.engine })
	}
	if(typeof data?.accelerator !== "undefined"){
		characteristics.push({ title: "Acelerador", value:  data?.accelerator })
	}
	if(typeof data?.maximumSpeed !== "undefined"){
		characteristics.push({ title: "Velocidad máxima", value: data?.maximumSpeed })
	}
	if(typeof data?.battery !== "undefined"){
		characteristics.push({ title: "Batería", value: data?.battery })
	}
	if(typeof data?.engine !== "undefined" ){
		characteristics.push({ title: "Motor", value: data?.engine })
	}

	if(typeof data?.autonomy !== "undefined"){
		characteristics.push({ title: "Batería", value: data?.autonomy })
	}
	if(typeof data?.usbOutput !== "undefined"){
		characteristics.push({ title: "Autonomía", value: data?.usbOutput })
	}
	if(typeof data?.display !== "undefined"){
		characteristics.push({ title: "Salida USB", value: data?.display })
	}
	if(typeof data?.light !== "undefined"){
		characteristics.push({ title: "Display", value: data?.light })
	}
	if(typeof data?.mudCovers !== "undefined"){
		characteristics.push({ title: "Luz", value: data?.mudCovers })
	}
	if(typeof data?.rearGrill !== "undefined"){
		characteristics.push({ title: "Tapabarros", value: data?.rearGrill })
	}
	if(typeof data?.accelerator !== "undefined"){
		characteristics.push({ title: "Parrilla trasera", value: data?.accelerator })	
	}
	if(typeof data?.bateryLife !== "undefined"){
		characteristics.push({ title: "Vida útil batería", value: data?.bateryLife })
	}
	if(typeof data?.transportTo !== "undefined"){
		characteristics.push({ title: "Transporte hasta", value: data?.transportTo })
	}
	if(typeof data?.weight !== "undefined"){
		characteristics.push({ title: "Peso", value: data?.weight })
	}
	if(typeof data?.batteryChargeIndicator !== "undefined"){
		characteristics.push({ title: "Indicador de carga de la batería", value: data?.batteryChargeIndicator })
	}
	if(typeof data?.transmission !== "undefined"){
		characteristics.push({ title: "Transmisión", value: data?.transmission })
	}
	if(typeof data?.traction !== "undefined"){
		characteristics.push({ title: "Fracción", value: data?.traction })
	}
	if(typeof data?.brake !== "undefined"){
		characteristics.push({ title: "Freno", value: data?.brake })
	}
	if(typeof data?.maximumUserWeight !== "undefined"){
		characteristics.push({ title: "Peso máximo del usuario", value: data?.maximumUserWeight })
	}
	if(typeof data?.netWeight !== "undefined"){
		characteristics.push({ title: "Peso neto", value: data?.netWeight })
	}
	if(typeof data?.chargingTime !== "undefined"){
		characteristics.push({ title: "Tiempo de carga", value: data?.chargingTime })
	}
	if(typeof data?.recommendedAge !== "undefined"){
		characteristics.push({ title: "Edad recomendada", value: data?.recommendedAge })
	}
	if(typeof data?.armedMeasures !== "undefined"){
		characteristics.push({ title: "Medidas armado", value: data?.armedMeasures })
	}
	if(typeof data?.loader !== "undefined" ){
		characteristics.push({ title: "Cargador", value: data?.loader })
	}
	if(typeof data?.certification !== "undefined"){
		characteristics.push({ title: "Certificación", value: data?.certification })
	}
	if(typeof data?.guarantee !== "undefined"){
		characteristics.push({ title: "Garantía", value: data?.guarantee })
	}

	return characteristics
}