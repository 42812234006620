//import { listMeta } from "hooks/MetaHead/ListMetaConcessionaire.json"
//import { listMetaServices } from "hooks/MetaHead/ListMetaService.json"
//import { listMetaTire } from "hooks/MetaHead/ListMetaTire.json"
import { getListMetaTags } from "services/actions/configActions"
//import MetaTagNew from "hooks/MetaHead/MetaTagNew.json"
//import MetaTagUsed from "hooks/MetaHead/MetaTagUsed.json"
import { useDispatch } from 'react-redux'

const DOMAIN = process.env.REACT_APP_URL

export const useConfig = () => {

    const dispatch = useDispatch()

    const getDataMetaTag = async (pathname) => {
        let domainSite = DOMAIN
        if(DOMAIN.endsWith('/')){
            domainSite = DOMAIN.slice(0, -1)
        }
        const body = {url: `${domainSite}${pathname}`}
        const result = await dispatch(getListMetaTags(body))
        let metaTag = result?.data!==null ? result?.data : { title: "", description: "", header: "", url: ""}
        return metaTag
    }

	return {
        getDataMetaTag
	}
}