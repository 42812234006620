import { useState } from "react";
import { searchTireByMeasure, selectedValueForm } from 'services/actions/serviceActions'

import { useDispatch, useSelector } from "react-redux";
import { useConfig } from "hooks/MetaHead/useConfig"
import { useAdvertising } from 'hooks/useAdvertising';


export const useSearchTire = () => {

	const dispatch = useDispatch()
	
	const { getDataMetaTag } = useConfig()
	const [ metaHead, setMetaHead ] = useState({})
	const { measures, resultServices, listTires, valueSearchMeasure, 
		resultTires, tireSelectedQuote } = useSelector((state) => state.services)
	const { advertisingTire, actionAdvertising } = useAdvertising()
	
	const getSearchInitial = async (location, brand) => {
		
		const infoMeta = await getDataMetaTag(location?.pathname)
		setMetaHead(infoMeta)

		let data = {...valueSearchMeasure, brand }
		if(valueSearchMeasure?.width==="" && valueSearchMeasure?.profile==="" && valueSearchMeasure?.ring===""){
			data = JSON.parse(localStorage.getItem("valueTire"))
			dispatch(selectedValueForm(data))
		}

		await dispatch(searchTireByMeasure(data))
	}

	const cleanSearchValue = () => {
		let data = {profile: "", width: "", ring: "", search: false}
		dispatch(selectedValueForm(data))
	}


	const handleMoveTo = (event, idPanel) => {
		event.preventDefault()
		document.getElementById(idPanel).scrollIntoView(true)
	  }

	const state = {
		measures, 
		resultServices, 
		listTires, 
		valueSearchMeasure, 
		resultTires, 
		tireSelectedQuote,
		metaHead,
		advertisingTire
	}

	const actions = {
		getSearchInitial,
		cleanSearchValue,
		 actionAdvertising,
		 handleMoveTo
	}

	return {
		state,
		actions
	}
}