const listMenu = [
    {
        to: '/',
        text: 'Inicio',
        textMob: 'Inicio',
        link: true,
        visibleDsk: false,
        visibleMob: true,
        isNew: false,
        listOne: [],
        listTwo: []
    },
    {
        to: '/autos/nuevos',
        text: 'Nuevos',
        textMob: 'Autos Nuevos',
        link: true,
        visibleDsk: true,
        visibleMob: true,
        isNew: false,
        listOne: [],
        listTwo: []
    },
    {
        to: '/autos/usados',
        text: 'Usados',
        textMob: 'Autos Usados',
        link: true,
        visibleDsk: true,
        visibleMob: true,
        isNew: false,
        listOne: [],
        listTwo: []
    },
    {
        to: '/motos',
        text: 'Motos',
        textMob: 'Motos',
        link: true,
        visibleDsk: true,
        visibleMob: true,
        isNew: false,
        listOne: [],
        listTwo: []
    },
    {
        to: '/servicio-automotriz',
        text: 'Servicios',
        textMob: 'Servicios & Accesorios',
        link: false,
        visibleDsk: true,
        visibleMob: true,
        isNew: false,
        listOne: [],
        listTwo: []
    },
    {
        to: '/servicio-automotriz/neumaticos',
        text: 'Neumáticos',
        textMob: 'Neumáticos',
        link: true,
        visibleDsk: true,
        visibleMob: true,
        isNew: false,
        listOne: [],
        listTwo: []
    },
    {
        to: '/ofertas',
        text: 'Ofertas',
        textMob: 'Ofertas',
        link: true,
        visibleDsk: true,
        visibleMob: true,
        isNew: false,
        listOne: [],
        listTwo: []
    }
]

export { listMenu }